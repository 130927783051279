import React, { FC } from "react";
import { Button, Card } from "react-bootstrap";
import { NetworkTest, NetworkTestState } from "../../models/types";
import { NetworkTestReport } from "./NetworkTestReport";

interface Props {
    test?: NetworkTest;
    startTest: () => void;
    startCancelTest: () => void;
    header?: string;
}

export const NetworkTestResult: FC<Props> = ({
    test,
    startTest,
    startCancelTest,
    header = "Network Validation"
}) => {
    return (
        <Card className="mt-2">
            <Card.Header className="text-inverse-50">
                <b>{header}</b>
            </Card.Header>
            <Card.Body>
                <NetworkTestReport
                    report={test ? test.report : undefined}
                ></NetworkTestReport>
                {test && test.state < NetworkTestState.Completed ? (
                    <Button
                        onClick={(event: any) => {
                            event.stopPropagation();
                            startCancelTest();
                        }}
                        variant="danger"
                        className="btn-sm"
                    >
                        Cancel
                    </Button>
                ) : (
                    <Button
                        onClick={(event: any) => {
                            event.stopPropagation();
                            startTest();
                        }}
                        className="btn-sm"
                    >
                        Test
                    </Button>
                )}
            </Card.Body>
        </Card>
    );
};
