import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { NavigationPanel } from "../panels/NavigationPanel";
import { OrganizationPage } from "./OrganizationPage";
import { AssetsPage } from "./AssetsPage";
import { useApolloClient, useQuery, useSubscription } from "@apollo/client";
import {
    BatchBuildsData,
    BatchBuildState,
    BatchBuildsVariables,
    BuildNotification,
    MeData,
    NetworkTest,
    NetworkTestState,
    NotificationType,
    StateData
} from "../../models/types";
import {
    GET_BATCH_BUILDS,
    GET_LOCAL_STATE,
    GET_ME
} from "../../graphql/queries";
import { useLocalState } from "../../graphql/hooks";
import { ProductsPage } from "./ProductsPage";
import { History } from "../views/History";
import { Row } from "react-bootstrap";
import { RevisionHistory } from "../views/RevisionHistory";
import { BUILD_PIPELINE_SUBSCRIPTION } from "../../graphql/subscriptions";
import { BuildReportWrapper } from "../views/BuildReportWrapper";
import { RevisionSubscriptions } from "../higherOrderComponents/RevisionSubscriptions";
import { CliAuthPage } from "./cli/CliAuthPage";
import { AnalyticsSchemaEditView } from "../views/analytics/AnalyticsSchemaEditView";
import { DeveloperNewProjectPage } from "./DeveloperNewProjectPage";
import { AnalyticsSubscriptions } from "../higherOrderComponents/AnalyticsSubscriptions";
import { AdminPage } from "./admin/AdminPage";
import { ProtectedRoute } from "../../auth/ProtectedRoute";
import { AditorView } from "../views/aditor/AditorView";
import { MutatorView } from "../views/mutator/MutatorView";
import { Signup } from "./Signup";
import { AnalyticsDashboard } from "./analytics/AnalyticsDashboard";
import { AditorTemplates } from "./api/AditorTemplates";
import cx from "classnames";
import "./pages.scss";

export const MainPage = () => {
    const client = useApolloClient();
    const state = useLocalState();

    const { loading, data: { me } = {} } = useQuery<MeData>(GET_ME);

    useSubscription(BUILD_PIPELINE_SUBSCRIPTION, {
        skip: me === undefined,
        onData: ({
            data: { data: { buildPipelineNotification } = {} },
            client
        }) => {
            const currentNotifications = state.buildNotifications;
            if (currentNotifications) {
                const build = buildPipelineNotification.build;
                const tests: NetworkTest[] =
                    buildPipelineNotification.networkTests;

                let isLink = true;
                let linkPath = `/products/${build.revision.project.product.id}/projects/${build.revision.project.id}/revisions/${build.revision.id}/history/${build.id}`;
                let title = "";
                let message = "";
                let notificationType = NotificationType.Info;
                if (tests) {
                    if (tests[0].isAutomatic) {
                        const testsFailed = tests.filter(
                            test => test.state === NetworkTestState.Failed
                        ).length;
                        const testsCompleted = tests.filter(
                            test => test.state === NetworkTestState.Completed
                        ).length;
                        let failed = testsFailed ? "Failed" : "Completed";
                        title =
                            tests[0].network !== "analytics"
                                ? `Network Validation ${failed}!`
                                : `Analytics Validation ${failed}!`;
                        notificationType =
                            testsFailed > 0
                                ? NotificationType.Danger
                                : NotificationType.Success;
                        const completed =
                            testsCompleted > 0
                                ? `completed ${testsCompleted}`
                                : "";
                        failed =
                            testsFailed > 0 ? ` failed ${testsFailed}` : "";
                        message = `Build ${build.id}: automatic tests ${completed}${failed}`;
                    } else {
                        switch (tests[0].state) {
                            case NetworkTestState.JobStarted: {
                                title =
                                    tests[0].network !== "analytics"
                                        ? "Network Validation Started!"
                                        : "Analytics Validation Started!";
                                notificationType = NotificationType.Info;
                                break;
                            }
                            case NetworkTestState.Completed: {
                                title =
                                    tests[0].network !== "analytics"
                                        ? "Network Validation Finished Successfully!"
                                        : "Analytics Validation Finished Successfully!";
                                notificationType = NotificationType.Success;
                                break;
                            }
                            case NetworkTestState.Failed: {
                                title =
                                    tests[0].network !== "analytics"
                                        ? "Network Validation Failed!"
                                        : "Analytics Validation Failed!";
                                notificationType = NotificationType.Danger;
                                break;
                            }
                            default:
                                console.log(
                                    "No notification needed for test state: ",
                                    tests[0].state
                                );
                                return;
                        }
                        message = `Build ${build.id}: manual test in state ${tests[0].state}`;
                    }
                } else if (build) {
                    const currentBuildsData = client.cache.readQuery<
                        BatchBuildsData,
                        BatchBuildsVariables
                    >({
                        query: GET_BATCH_BUILDS,
                        variables: {
                            revisionId:
                                buildPipelineNotification.build.revision.id
                        }
                    });

                    const currentBuilds = currentBuildsData?.builds;
                    if (currentBuilds && buildPipelineNotification.build) {
                        const updatedBuilds = currentBuilds.map(item =>
                            item.id === buildPipelineNotification.build.id
                                ? buildPipelineNotification.build
                                : item
                        );

                        client.cache.writeQuery<
                            BatchBuildsData,
                            BatchBuildsVariables
                        >({
                            query: GET_BATCH_BUILDS,
                            variables: {
                                revisionId:
                                    buildPipelineNotification.build.revision.id
                            },
                            data: { builds: updatedBuilds }
                        });
                    }

                    switch (build.state) {
                        case BatchBuildState.Started: {
                            notificationType = NotificationType.Info;
                            title = "Build Started!";
                            message = `Revision: ${build.revision.name} has started build: ${build.id}`;
                            isLink = false;
                            break;
                        }
                        case BatchBuildState.Failed: {
                            notificationType = NotificationType.Danger;
                            title = "Build Failed!";
                            message = `Revision: ${build.revision.name} has finished a failed build: ${build.id}`;
                            linkPath = `/products/${build.revision.project.product.id}/projects/${build.revision.project.id}/revisions/${build.revision.id}/history`;
                            break;
                        }
                        case BatchBuildState.Completed: {
                            notificationType = NotificationType.Success;
                            title = "Build Finished Successfully!";
                            message = `Revision: ${build.revision.name} has finished build: ${build.id}`;
                            break;
                        }
                        default:
                            console.log(
                                "No notification needed for build state: ",
                                build.state
                            );
                            return;
                    }
                }

                const tempNotification: BuildNotification = {
                    id: currentNotifications.length + 1,
                    title: title,
                    isNew: true,
                    isLink: isLink,
                    type: notificationType,
                    path: linkPath,
                    message: message
                };

                const updatedNotifications = [
                    ...currentNotifications,
                    tempNotification
                ];
                client.writeQuery<StateData, StateData>({
                    query: GET_LOCAL_STATE,
                    data: {
                        state: {
                            ...state,
                            buildNotifications: updatedNotifications
                        }
                    }
                });
            }
        }
    });

    if (!loading && !state.initialized) {
        const isDeveloper =
            (me && me.scopes && me.scopes.includes("admin")) ||
            (me && Number(me.organization?.id) === 1) ||
            false;
        const useDarkMode = (me && me.useDarkMode) || false;
        client.writeQuery<StateData, StateData>({
            query: GET_LOCAL_STATE,
            data: {
                state: {
                    ...state,
                    initialized: true,
                    isDeveloper: isDeveloper,
                    developerMode: isDeveloper,
                    useDarkMode: useDarkMode
                }
            }
        });
        document.documentElement.style.setProperty(
            "background-color",
            useDarkMode ? "#36393f" : "#ffffff"
        );
    }

    return (
        <div
            className={cx(
                "main",
                { "theme-light": !state.useDarkMode },
                { "theme-dark": state.useDarkMode }
            )}
        >
            <NavigationPanel />
            <Row className="m-0 p-0">
                <Routes>
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/cli/auth" element={<CliAuthPage />} />
                    <Route
                        path="/organization"
                        element={<OrganizationPage />}
                    />
                    <Route path="/assets" element={<AssetsPage />} />
                    <Route
                        path="/analytics"
                        element={<AnalyticsSubscriptions />}
                    />
                    <Route
                        path="/analyticsDashboard"
                        element={<AnalyticsDashboard />}
                    />
                    <Route
                        path="/products/:productId/projects/:projectId/revisions/:revisionId/history/:jobId/analyticsSchema"
                        element={<AnalyticsSchemaEditView />}
                    />
                    <Route
                        path="/products/:productId/projects/:projectId/revisions/:revisionId/history/:jobId"
                        element={<BuildReportWrapper />}
                    />
                    <Route
                        path="/products/:productId/projects/:projectId/revisions/:revisionId/history"
                        element={<History isFullHistory={true} />}
                    />
                    <Route
                        path="/products/:productId/projects/:projectId/revisions/:revisionId/events"
                        element={<RevisionHistory />}
                    />
                    <Route
                        path="/products/:productId/projects/:projectId/revisions/:revisionId/:variationView/aditor"
                        element={<AditorView />}
                    />
                    <Route
                        path="/products/:productId/projects/:projectId/revisions/:revisionId/:variationView/customator"
                        element={<MutatorView />}
                    />
                    <Route
                        path="/products/:productId/projects/:projectId/revisions/:revisionId/:variationView"
                        element={<RevisionSubscriptions />}
                    />
                    <Route path="/products" element={<ProductsPage />} />
                    <Route
                        path="/createNewProject"
                        element={<DeveloperNewProjectPage />}
                    ></Route>
                    <Route path="api">
                        <Route
                            path="aditorTemplates"
                            element={<AditorTemplates />}
                        />
                    </Route>
                    <Route
                        path="/admin"
                        element={
                            <ProtectedRoute
                                scopes={[
                                    "admin",
                                    "organizations-admin",
                                    "organization-admin"
                                ]}
                            >
                                <AdminPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="*"
                        element={<Navigate to="/products" replace />}
                    />
                </Routes>
            </Row>
        </div>
    );
};
