import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowRight,
    faBalanceScale,
    faFilter,
    faInfoCircle,
    faQuestionCircle
} from "@fortawesome/free-solid-svg-icons";
import { Card } from "react-bootstrap";
import styles from "./QueryGuide.module.scss";

export const QueryGuide = () => {
    return (
        <Card className="mx-0 mx-lg-5 mt-3 mb-1">
            <Card.Body>
                <Card className="inner-card">
                    <Card.Header className="text-inverse-50">
                        <h4>Analytics Overview</h4>
                    </Card.Header>
                    <Card.Body className="text-inverse bread-text p-4">
                        <p>
                            <i>
                                Instructions{" "}
                                <a
                                    href="https://app.clickup.com/20449045/v/dc/kg1rn-15944/kg1rn-36992"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    in Clickup
                                </a>{" "}
                            </i>
                        </p>
                        <p>
                            Analytics data collected from ads is accessible in
                            the <b>Funnels</b> view. Charts and other visual
                            components are generated from the retrieved data for
                            better representation and analysis.
                        </p>

                        <ul className={styles.list}>
                            <li>
                                Items in <b>Query Selection</b> provide details
                                about builds, schemas, and available data for
                                charting.
                                <div>
                                    <FontAwesomeIcon
                                        icon={faArrowRight}
                                        size="xs"
                                    />{" "}
                                    Irrelevant builds can be excluded, ensuring
                                    focused analysis.
                                </div>
                            </li>
                            <li>
                                Items selected for comparison mode (
                                <FontAwesomeIcon
                                    icon={faBalanceScale}
                                    color="lightgreen"
                                />
                                ) generate parallel charts or components for A/B
                                testing.
                            </li>
                            <li>
                                Items selected to query include builds with{" "}
                                <b>Funnel events</b> defined in their schemas.
                            </li>
                            <li>
                                Users can make a custom funnel from available
                                events, which is then used to query the data
                                from the Seepia database.
                            </li>
                        </ul>
                    </Card.Body>
                </Card>
                <h4 className="text-inverse-50 pt-2 m-3">Query Settings</h4>
                <Card className="mt-2 inner-card">
                    <Card.Header className="text-inverse-50">
                        <h5>Query Selection</h5>
                    </Card.Header>
                    <Card.Body className="text-inverse bread-text">
                        <ul className={styles.list}>
                            <li>
                                Selected items (via toggle buttons{" "}
                                <FontAwesomeIcon
                                    icon={faFilter}
                                    color="white"
                                />
                                ) are added to the query (button turns purple)
                                and comparison mode (
                                <FontAwesomeIcon
                                    icon={faBalanceScale}
                                    color="lightgreen"
                                />
                                ).
                            </li>
                            <li>
                                Expand or collapse to view lower-level items,
                                all the way to network builds (individual
                                playable ads).
                            </li>
                            <li>
                                Analytics data (daily and/or user-queried) is
                                displayed if available
                            </li>
                        </ul>
                        <h6 className="ps-3 pt-2">Indicators</h6>
                        <ul className={styles.list}>
                            <li>
                                <b>Green scales icon:</b> Item and all its
                                children (unless any of them are manually
                                excluded) are selected for comparison.
                                <div>
                                    <FontAwesomeIcon
                                        icon={faArrowRight}
                                        size="xs"
                                    />{" "}
                                    Note: The funnel toggle must be activated
                                    (purple) to retrieve data.
                                </div>
                            </li>
                            <li>
                                <b>Checkmark:</b> Manual selection state allows
                                inclusion for comparison even if higher-level
                                items are excluded.
                            </li>
                            <li>
                                <b>Purple color:</b> Toggle button color
                                indicates the item's data is included in the
                                query.
                            </li>
                        </ul>
                        <h6 className="ps-3 pt-2">Notes</h6>
                        <ul className={styles.list}>
                            <li>
                                When any item is set to <b>comparison mode</b>,
                                only the lower levels of the selected items (and
                                other items in comparison modes) are queried.
                                <div>
                                    <FontAwesomeIcon
                                        icon={faArrowRight}
                                        size="xs"
                                    />{" "}
                                    Data selected <i>only</i> via activated
                                    (purple) toggle button will not be included
                                    in this case!
                                </div>
                            </li>
                            <li>
                                It is possible to de-select all actual
                                data-containing lower level items manually.
                                Buttons enable automatic selection/deselection
                                for items not in <b>manual mode</b> (indicated
                                by check or X marks).
                            </li>
                            <li>
                                Navigating through{" "}
                                <FontAwesomeIcon
                                    icon={faInfoCircle}
                                    size="1x"
                                    color="gray"
                                />{" "}
                                and <b>Go to Schema</b> -link and returning to
                                funnel view via Back button does not reset the
                                funnel Query selection.{" "}
                            </li>
                        </ul>
                    </Card.Body>
                </Card>
                <Card className="mt-2 inner-card">
                    <Card.Header className="text-inverse-50">
                        <h5>Query Options</h5>
                    </Card.Header>
                    <Card.Body className="text-inverse bread-text">
                        <ul className={styles.list}>
                            <li>
                                Select the data retrieval time range via the
                                calendar tool or <b>Time Range</b> dropdown.
                            </li>
                            <li>
                                {" "}
                                Check <b>Performance Comparison</b> to produce a
                                view of best and least performing ads in the
                                selection with key analytics data and links to
                                those playables. Ads with +1000 adStarts are
                                included.
                            </li>
                            <li>
                                {" "}
                                Check <b>Playable Preview</b> to produce a
                                preview window where ads included in the
                                selection can be played and viewed.
                            </li>
                            <li>
                                Check <b>Day Funnels</b> to produce day by day
                                funnels for selected events.
                            </li>
                        </ul>
                    </Card.Body>
                </Card>
                <Card className="mt-2 inner-card">
                    <Card.Header className="text-inverse-50">
                        <h5>Funnel Events</h5>
                    </Card.Header>
                    <Card.Body className="text-inverse bread-text">
                        <ul className={styles.list}>
                            <li>
                                Custom funnels are created based on (Build
                                specific) <b>analytics schemas</b> and their
                                defined events.
                            </li>
                            <li>
                                Add events to funnel via dropdown menus and add
                                new dropdowns using the <b>Add event...</b>{" "}
                                option.
                            </li>
                            <li>
                                Check <b>Exclude</b> on an optional event to not
                                show that event's data in funnel charts.{" "}
                                <div>
                                    <FontAwesomeIcon
                                        icon={faArrowRight}
                                        size="xs"
                                    />{" "}
                                    Eg. <b>adLose</b> contains the share of
                                    users losing the playable; by excluding the
                                    event only winning gameplays are included in
                                    the data.
                                </div>
                            </li>
                        </ul>
                        <h6 className="ps-3 pt-2">Guidelines</h6>
                        <ul className={styles.list}>
                            <li>
                                A funnel must include <b>at least two events</b>
                                ; removal is enabled after adding a third event.
                            </li>
                            <li>Rearrange events by dragging them.</li>
                            <li>
                                In cases of conflicting schemas, only shared
                                events appear in the dropdown (e.g.,{" "}
                                <b>adStart</b> and <b>ctaBtnPressed</b>).
                            </li>
                        </ul>
                        <h6 className="ps-3 pt-2">Additional Event Data</h6>
                        <ul className={styles.list}>
                            <li>
                                Use the <b>plus button</b> <i>Add Event Data</i>{" "}
                                to define events further.
                            </li>
                            <li>
                                Tooltips in question mark (
                                <FontAwesomeIcon
                                    icon={faQuestionCircle}
                                    color="gray"
                                />
                                ) display comments from schema explaining
                                available Event Data variables or usage
                                contexts.
                            </li>
                            <li>
                                Eg. An event with additional Event Data
                                key/value set to <b>portrait</b> and <b>true</b>{" "}
                                fetches that event's data only from portrait
                                mode.
                            </li>
                        </ul>
                    </Card.Body>
                </Card>

                <h4 className="text-inverse-50 m-3 pt-2">Query Results</h4>
                <Card className="mt-2 inner-card">
                    <Card.Header className="text-inverse-50">
                        <h5>Funnels</h5>
                    </Card.Header>
                    <Card.Body className="text-inverse bread-text">
                        <ul className={styles.list}>
                            <li>
                                <b>Funnels</b> illustrate the progression of
                                selected events, showing the number of users
                                completing each step through the selected chart
                                mode.
                            </li>
                            <li>
                                Select a desirable chart type (
                                <b>Visual Funnel</b>, <b>Bar Chart</b> or{" "}
                                <b>Line Chart</b>) from the tabs.
                            </li>
                            <li>
                                The data is same in all chart types and they are
                                only for different visual representations!
                            </li>
                        </ul>
                    </Card.Body>
                </Card>
                <Card className="mt-2 inner-card">
                    <Card.Header className="text-inverse-50">
                        <h5>(Playable) Performance Comparison</h5>
                    </Card.Header>
                    <Card.Body className="text-inverse bread-text">
                        <ul className={styles.list}>
                            <li>
                                Highlights{" "}
                                <b>best and least performing network builds</b>{" "}
                                in the query with links to the ads themselves or
                                detailed build reports (open in new windows).
                            </li>
                            <li>
                                Only builds with analytical data and 1000+
                                adStart events are included to exclude testing
                                related data.
                            </li>
                        </ul>
                    </Card.Body>
                </Card>
                <Card className="mt-2 inner-card">
                    <Card.Header className="text-inverse-50">
                        <h5>Time Medians</h5>
                    </Card.Header>
                    <Card.Body className="text-inverse bread-text">
                        <ul className={styles.list}>
                            <li>
                                Time medians present the median amount of time
                                users spend between the steps.
                            </li>
                            <li>
                                {" "}
                                <b>Cumulative time</b> linechart indicates time
                                used so far in total.
                            </li>
                            <li>
                                In comparison mode, compared items are drawn in
                                the same chart with their own colored lines and
                                bars.
                            </li>
                        </ul>
                    </Card.Body>
                </Card>
                <Card className="mt-2 inner-card">
                    <Card.Header className="text-inverse-50">
                        <h5>Time Distribution</h5>
                    </Card.Header>
                    <Card.Body className="text-inverse bread-text">
                        <ul className={styles.list}>
                            <li>
                                Time distribution charts compare amount of users
                                to time (s) used between the steps in the
                                funnel.
                            </li>
                            <li>
                                {" "}
                                Disable "<b>Show +60</b>" to exclude idle users
                                spending over a minute in the ad to reduce chart
                                distortion.
                            </li>
                        </ul>
                    </Card.Body>
                </Card>
                <Card className="mt-2 inner-card">
                    <Card.Header className="text-inverse-50">
                        <h5>Playable Preview</h5>
                    </Card.Header>
                    <Card.Body className="text-inverse bread-text">
                        <ul className={styles.list}>
                            <li>
                                Network builds that contain impressions data and
                                are included in the query are listed in the
                                dropdown for previewing in the window below.
                            </li>
                            <li>
                                The previewed ad is fully interactable and
                                should start playing on selection.
                            </li>
                            <li>
                                In comparison mode, all eligible network builds
                                are listed in the same dropdown. Check the name
                                of the desired ad from the Query selection!
                            </li>
                        </ul>
                    </Card.Body>
                </Card>
                <Card className="mt-2 inner-card">
                    <Card.Header className="text-inverse-50">
                        <h5>Heatmaps</h5>
                    </Card.Header>
                    <Card.Body className="text-inverse bread-text">
                        <ul className={styles.list}>
                            <li>
                                <b>Note!</b> Heatmap charts are currently
                                generated only when <b>ctaBtnPressed</b> is
                                included in the funnel as an event.
                            </li>
                            <li>
                                Heatmaps display tap counts on user screens
                                normalized to grid coordinates in portrait and
                                landscape modes.
                            </li>
                            <li>
                                Landscape and portrait modes contain separate
                                data from user device orientations and are not
                                only a visual representation!
                            </li>
                            <li>
                                Colors indicate the intensity ie. the count of
                                taps on the specific coordinates.
                            </li>
                        </ul>
                    </Card.Body>
                </Card>
                <Card className="mt-2 inner-card">
                    <Card.Header className="text-inverse-50">
                        <h5>Day Funnels</h5>
                    </Card.Header>
                    <Card.Body className="text-inverse bread-text">
                        <ul className={styles.list}>
                            <li>
                                Day Funnel charts give insight to{" "}
                                <b>daily fluctuations of activity</b> in the
                                funnel events.
                            </li>
                            <li>
                                The first chart shows total event count of that
                                event, and the charts show the amount of users
                                (%) transferred from the previous event on a
                                given day. For reference, total counts are
                                included in the tooltips.
                            </li>
                            <li>
                                Items set in comparison mode are drawn in the
                                same charts with their own colored lines.
                            </li>
                        </ul>
                    </Card.Body>
                </Card>
            </Card.Body>
        </Card>
    );
};
