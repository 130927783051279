import React, { Component } from "react";
import { DSVRowArray } from "d3";
import { AnalyticsQueryType, AthenaDatasetResult } from "../../models/types";
import { loadCsv, pastelStepColor } from "../../common/AnalyticsHelpers";
import {
    CategoryScale,
    Chart,
    ChartConfiguration,
    LinearScale
} from "chart.js";
import { FunnelController, TrapezoidElement } from "chartjs-chart-funnel";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ChartJsWrapper } from "./ChartJsWrapper";

Chart.register(FunnelController, TrapezoidElement, LinearScale, CategoryScale);

interface State {
    csvData: DSVRowArray | undefined;
}

interface Props {
    data: AthenaDatasetResult[];
    darkMode?: boolean;
}

export class FunnelVisualChart extends Component<Props> {
    state: State = {
        csvData: undefined
    };

    componentDidMount(): void {
        const { data } = this.props;
        const funnelData = data[0].dataset.find(
            element => element.type === AnalyticsQueryType.Funnel
        );
        if (funnelData?.success && funnelData.message) {
            loadCsv(funnelData.message)
                .then(result => {
                    this.setState({ csvData: result });
                })
                .catch(error => console.log("[DEBUG] error ", error));
        }
    }

    render() {
        const { darkMode } = this.props;
        const { csvData } = this.state;
        if (!csvData || csvData.length === 0) {
            return (
                <div className="p-3 text-inverse-50 analytics-font">
                    No data found. <br />
                    Try selecting a different time range and/or Query selection.
                </div>
            );
        }

        const { columns, ...cleanData } = csvData;

        const datasets: number[] = [];
        const labels: string[] = [];
        const colours: string[] = [];
        const percentages: string[] = [];

        for (const key in cleanData) {
            // @ts-ignore
            labels.push(cleanData[key].eventName);
            datasets.push(Number(cleanData[key].events));
        }

        for (let i = 0; i < datasets.length; i++) {
            if (i === 0) {
                percentages.push("");
            } else if (datasets[i - 1] > 0) {
                const percentage = (datasets[i] / datasets[i - 1]) * 100;
                percentages.push(` ${percentage.toFixed(1)} %`);
            } else {
                percentages.push("0.0%");
            }
        }

        for (let i = 0; i < labels.length; i++) {
            colours.push(pastelStepColor(i / (labels.length - 1), 0.7));
        }

        const config: ChartConfiguration = {
            type: "funnel",
            data: {
                labels: labels,
                datasets: [
                    {
                        label: "Count",
                        data: datasets,
                        backgroundColor: colours,
                        borderWidth: 1,
                        datalabels: {
                            color: darkMode ? "white" : "#2a2c30",
                            font: {
                                size: 12
                            },
                            formatter: (value, context) => {
                                const label =
                                    //@ts-ignore
                                    context.chart.data.labels[
                                        context.dataIndex
                                    ];
                                const percentage =
                                    percentages[context.dataIndex];
                                const count = datasets[context.dataIndex];
                                const formattedCount = count.toLocaleString();

                                if (context.dataIndex === 0) {
                                    return [`${label}`, `${formattedCount}`];
                                }
                                return `${label}${percentage}`;
                            }
                        }
                    }
                ]
            },
            options: {
                indexAxis: "y",
                responsive: true,
                maintainAspectRatio: false
            },
            plugins: [ChartDataLabels]
        };

        return <ChartJsWrapper config={config} />;
    }
}
