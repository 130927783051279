import { gql } from "@apollo/client/core";

export const NOTIFICATION_SUBSCRIPTION = gql`
    subscription userNotification($userId: ID!) {
        userNotification(userId: $userId) {
            id
            title
            message
            isNew
            dateCreated
            dateUpdated
        }
    }
`;

export const REVISION_SUBSCRIPTION = gql`
    subscription revisionNotification($revisionId: ID!) {
        revisionNotification(revisionId: $revisionId) {
            version
            dateUpdated
        }
    }
`;
export const PRODUCT_CHANGED_SUBSCRIPTION = gql`
    subscription productChangedNotification {
        productChangedNotification {
            action
            product {
                id
                name
                dateUpdated
            }
        }
    }
`;

export const PRODUCT_METADATA_CHANGED_SUBSCRIPTION = gql`
    subscription productMetaChangedNotification {
        productMetaChangedNotification {
            id
            development
            review
            accepted
            rejected
            released
            bugged
            testAvailable
        }
    }
`;

export const PROJECT_CHANGED_SUBSCRIPTION = gql`
    subscription projectChangedNotification($productId: ID!) {
        projectChangedNotification(productId: $productId) {
            action
            project {
                id
                name
                state
                gitProjectId
                gitProjectName
                gitProjectBranch
                slackChannel
                userImpressions
                userGameplay
                userEndScreen
                impressions
                gameplay
                endScreen
                dateUpdated
            }
        }
    }
`;

export const PROJECT_METADATA_CHANGED_SUBSCRIPTION = gql`
    subscription projectMetaChangedNotification($productId: ID!) {
        projectMetaChangedNotification(productId: $productId) {
            id
            development
            review
            accepted
            rejected
            released
            bugged
            testAvailable
        }
    }
`;

export const REVISION_CHANGED_SUBSCRIPTION = gql`
    subscription revisionChangedNotification($projectId: ID!) {
        revisionChangedNotification(projectId: $projectId) {
            action
            rId
            revision {
                id
                name
                branch
                gitId
                releaseType
                analyticsConfig
                archived
                userImpressions
                userGameplay
                userEndScreen
                impressions
                gameplay
                endScreen
                dateCreated
                dateUpdated
                project {
                    id
                    name
                    product {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const CURRENT_BUILDS_SUBSCRIPTION = gql`
    subscription currentBuildsNotification($revisionId: ID!) {
        currentBuildsNotification(revisionId: $revisionId) {
            builds
            version
        }
    }
`;

export const BUILD_PIPELINE_SUBSCRIPTION = gql`
    subscription buildPipelineNotification {
        buildPipelineNotification {
            networkTests {
                id
                state
                isAutomatic
                networkBuild {
                    id
                }
                report
                network
            }
            build {
                id
                dateCreated
                state
                buildJobCount
                jobIds
                revision {
                    id
                    name
                    project {
                        id
                        product {
                            id
                        }
                    }
                }
                networkBuilds {
                    id
                    state
                    arrayIndex
                    errors
                }
            }
        }
    }
`;

export const BUILD_CHANGED_SUBSCRIPTION = gql`
    subscription buildChangedNotification($projectId: ID!) {
        buildChangedNotification(projectId: $projectId) {
            buildState
            buildId
        }
    }
`;

export const BUILD_HISTORY_CHANGED_SUBSCRIPTION = gql`
    subscription buildHistoryChangedNotification($projectId: ID!) {
        buildHistoryChangedNotification(projectId: $projectId) {
            buildState
            buildId
        }
    }
`;

export const REVISION_CONFIG_SUBSCRIPTION = gql`
    subscription revisionConfigNotification($revisionId: ID!) {
        revisionConfigNotification(revisionId: $revisionId) {
            id
            localizations
            setBlacklist
        }
    }
`;

export const ORGANIZATIONS_SUBSCRIPTION = gql`
    subscription organizationNotification {
        organizationNotification {
            id
            name
            organizationType
        }
    }
`;

export const READY_SUBSCRIPTION = gql`
    subscription readyNotification($id: ID!) {
        readyNotification(id: $id) {
            success
            link
            type
        }
    }
`;

export const TASK_READY_SUBSCRIPTION = gql`
    subscription taskReadyNotification($userId: ID!) {
        taskReadyNotification(userId: $userId) {
            success
            taskId
            queryType
            results
            allComplete
        }
    }
`;

export const TASK_READY_FUNNEL_SUBSCRIPTION = gql`
    subscription taskReadyFunnelNotification($userId: ID!) {
        taskReadyFunnelNotification(userId: $userId) {
            success
            taskId
            queryType
            results
            allComplete
        }
    }
`;

export const CREATE_PROJECT_SUBSCRIPTION = gql`
    subscription createProjectNotification($taskId: String!) {
        createProjectNotification(taskId: $taskId) {
            success
            message
            state
        }
    }
`;

////////////
// Aditor //
////////////
export const ADITOR_OPTIMIZE_ASSETS_SUBSCRIPTION = gql`
    subscription aditorAssetReadyNotification($taskId: String!) {
        aditorAssetReadyNotification(taskId: $taskId) {
            success
            originalSize
            optimizedSize
            url
            error
        }
    }
`;

export const ADITOR_PLAY_SUBSCRIPTION = gql`
    subscription aditorPlayNotification($taskId: String!) {
        aditorPlayNotification(taskId: $taskId) {
            success
            url
            error
        }
    }
`;

////////////
// Mutator //
////////////
export const MUTATOR_BUILD_SUBSCRIPTION = gql`
    subscription mutatorBuildNotification($revisionId: ID!) {
        mutatorBuildNotification(revisionId: $revisionId) {
            mutatorAssetbundles {
                name
                path
                url
            }
        }
    }
`;

export const MUTATOR_CONFIG_SUBSCRIPTION = gql`
    subscription mutatorConfigNotification($revisionId: ID!) {
        mutatorConfigNotification(revisionId: $revisionId) {
            mutatorConfig
        }
    }
`;
