import React, { Component } from "react";
import { loadCsv, pastelStepColor } from "../../common/AnalyticsHelpers";
import { AnalyticsQueryType, AthenaDatasetResult } from "../../models/types";
import { DSVRowArray } from "d3";
import { ChartConfiguration } from "chart.js";
import { ChartJsWrapper } from "./ChartJsWrapper";

interface State {
    csvData: DSVRowArray | undefined;
}

interface Props {
    data: AthenaDatasetResult[];
}

export class FunnelBarChart extends Component<Props> {
    state: State = {
        csvData: undefined
    };

    shouldComponentUpdate(_: Readonly<Props>, nextState: Readonly<State>) {
        return this.state.csvData !== nextState.csvData;
    }

    componentDidMount(): void {
        const { data } = this.props;
        const funnelData = data[0].dataset.find(
            element => element.type === AnalyticsQueryType.Funnel
        );
        if (funnelData?.success && funnelData.message) {
            loadCsv(funnelData.message)
                .then(result => {
                    this.setState({ csvData: result });
                })
                .catch(error => console.log("[DEBUG] error ", error));
        }
    }

    render() {
        const { csvData } = this.state;
        if (!csvData || csvData.length === 0) {
            return (
                <div className="p-3 text-inverse-50 analytics-font">
                    No data found. <br />
                    Try selecting a different time range and/or Query selection.
                </div>
            );
        }

        const { columns, ...cleanData } = csvData;

        const datasets: number[] = [];
        const formattedLabels: string[] = [];
        const eventNames: string[] = [];
        const colours: string[] = [];
        let last = 100;
        let count = 0;

        for (const key in cleanData) {
            const eventCount = Number(cleanData[key].events);
            const formattedCount = eventCount.toLocaleString();
            const percentageString =
                count === 0
                    ? ""
                    : `  ${(
                          (Number(cleanData[key].events) / last) *
                          100
                      ).toFixed(1)} %`;
            last = eventCount;
            // @ts-ignore
            eventNames.push(cleanData[key].eventName);

            formattedLabels.push(
                `${cleanData[key].eventName}\n${formattedCount}\n${percentageString}`
            );

            datasets.push(eventCount);
            count++;
        }

        for (let i = 0; i < formattedLabels.length; i++) {
            colours.push(pastelStepColor(i / (formattedLabels.length - 1), 1));
        }

        const config: ChartConfiguration = {
            type: "bar",
            data: {
                labels: eventNames,
                datasets: [
                    {
                        label: "Count",
                        data: datasets,
                        backgroundColor: colours
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        ticks: {
                            autoSkip: false,
                            callback: function (value, index) {
                                const parts =
                                    formattedLabels[index].split("\n");
                                return parts;
                            }
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    }
                }
            }
        };
        return <ChartJsWrapper config={config} />;
    }
}
