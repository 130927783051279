import React, { FC } from "react";
import { NetworkTestReportField } from "./NetworkTestReportField";
import { TestReport } from "../../models/types";

interface Props {
    report?: TestReport;
}

export const NetworkTestReport: FC<Props> = ({ report }) => {
    if (!report) {
        return null;
    }

    const keys = Object.keys(report);
    const results = keys.map((key, index) => {
        return <NetworkTestReportField key={index} reportField={report[key]} />;
    });

    return <>{results}</>;
};
