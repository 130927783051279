import React, { FC } from "react";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import { GET_USER_ANALYTICS_QUERY_BUILD_ANALYTIC } from "../../../graphql/queries";
import { useQuery } from "@apollo/client";
import {
    DailyAnalyticBuildVariables,
    UserQueryAnalyticsBuildData
} from "../../../models/types";
import { formatDateRange } from "../../../common/AnalyticsHelpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface Props {
    id: number;
}

const UserQueryNetworkBuildAnalyticsImplementation: FC<Props> = ({ id }) => {
    const { analyticsEnabled } = useLocalAnalyticsState();
    const savedDateRange = localStorage.getItem("user-query-date-range");
    const parsedDateRange = savedDateRange
        ? JSON.parse(savedDateRange)
        : undefined;

    const { data: { getUserQueryBuildAnalytic } = {} } = useQuery<
        UserQueryAnalyticsBuildData,
        DailyAnalyticBuildVariables
    >(GET_USER_ANALYTICS_QUERY_BUILD_ANALYTIC, {
        variables: { id: id },
        skip:
            !analyticsEnabled || !savedDateRange || parsedDateRange.length === 0
    });

    if (
        !getUserQueryBuildAnalytic ||
        getUserQueryBuildAnalytic.impressions <= 0 ||
        !savedDateRange ||
        parsedDateRange.length === 0
    ) {
        return null;
    }

    const formattedDateRange = parsedDateRange
        ? formatDateRange(parsedDateRange[0], parsedDateRange[1])
        : "N/A";

    const gameplay = getUserQueryBuildAnalytic.gameplay || 0;
    const endScreen = getUserQueryBuildAnalytic.endScreen || 0;
    const ctr = (
        ((gameplay + endScreen) / getUserQueryBuildAnalytic.impressions) *
        100
    ).toFixed(1);

    return (
        <div className="d-flex analytics-font float-end px-2">
            <OverlayTrigger
                overlay={
                    <Tooltip id="network-impressions-tooltip">
                        {`Impressions - ${formattedDateRange}`}
                    </Tooltip>
                }
                delay={{
                    show: 100,
                    hide: 200
                }}
            >
                <div className="me-2">
                    {getUserQueryBuildAnalytic.impressions.toLocaleString()}
                </div>
            </OverlayTrigger>
            <div className="text-secondary">|</div>
            <OverlayTrigger
                overlay={
                    <Tooltip id="network-ctr-tooltip">
                        {`CTR - ${formattedDateRange}`}
                    </Tooltip>
                }
                delay={{
                    show: 100,
                    hide: 200
                }}
            >
                <div style={{ whiteSpace: "nowrap" }} className="ms-2">
                    {ctr} %
                </div>
            </OverlayTrigger>
        </div>
    );
};

export const UserQueryNetworkBuildAnalytics = React.memo(
    UserQueryNetworkBuildAnalyticsImplementation
);
