import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSort,
    faSortDown,
    faSortUp,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { SortingMode } from "../../models/types";
import { useLocalState, useSortingState } from "../../graphql/hooks";
import { useApolloClient } from "@apollo/client";
import { updateSortingState } from "../../common/Helpers";
import styles from "./SortButton.module.scss";

interface Props {
    sortingModes: SortingMode[];
}

export const SortingButton: React.FC<Props> = ({ sortingModes }) => {
    const client = useApolloClient();
    const sortingState = useSortingState();
    const { useDarkMode } = useLocalState();
    const [showSortingModal, setShowSortingModal] = useState(false);
    const [showUserQueryModal, setShowUserQueryModal] = useState(false);
    const savedDateRange = localStorage.getItem("user-query-date-range");

    const index = sortingState.sortingPriorities.findIndex(element =>
        sortingModes.includes(element)
    );
    const sortingMode =
        sortingState.sortingPriorities.find(element =>
            sortingModes.includes(element)
        ) || SortingMode.None;

    const handleButtonClick = () => {
        const userAnalyticsSortModes = [7, 8, 11, 12];
        const isUserQuerySort = sortingModes.some(mode =>
            userAnalyticsSortModes.includes(mode)
        );
        if (isUserQuerySort && !savedDateRange) {
            setShowUserQueryModal(true);
            return;
        }

        const uniqueModes: Set<SortingMode> = new Set(
            sortingState.sortingPriorities.filter(p => p !== SortingMode.None)
        );

        if (uniqueModes.size >= 3 && !uniqueModes.has(sortingMode)) {
            setShowSortingModal(true);
        } else {
            const newSortingMode =
                sortingModes[0] === sortingMode
                    ? sortingModes[1]
                    : sortingModes[0];
            updateSortingState(client, index, newSortingMode);
        }
    };

    const handleCloseClick = () => {
        updateSortingState(client, index, SortingMode.None);
    };

    let sortingIcon: IconDefinition = faSort;
    let iconStyle: React.CSSProperties = {
        marginLeft: "0.03rem"
    };

    switch (sortingMode) {
        case SortingMode.NameAscending:
        case SortingMode.DateAscending:
        case SortingMode.CtrAscending:
        case SortingMode.UserCtrAscending:
        case SortingMode.ImpressionsAscending:
        case SortingMode.UserImpressionsAscending:
            sortingIcon = faSortUp;
            iconStyle = {
                ...iconStyle,
                marginTop: "0.25rem"
            };
            break;
        case SortingMode.NameDescending:
        case SortingMode.DateDescending:
        case SortingMode.CtrDescending:
        case SortingMode.UserCtrDescending:
        case SortingMode.ImpressionsDescending:
        case SortingMode.UserImpressionsDescending:
            sortingIcon = faSortDown;
            iconStyle = {
                ...iconStyle,
                marginBottom: "0.3rem"
            };
            break;
    }
    let tooltipText;
    switch (index + 1) {
        case 1:
            tooltipText = "Priority 1 - sorts Products, Projects and Revisions";
            break;
        case 2:
            tooltipText = "Priority 2 - sorts Projects and Revisions";
            break;
        case 3:
            tooltipText = "Priority 3 - sorts Revisions";
            break;
        default:
            tooltipText = "Sort items";
    }
    return (
        <>
            <div className={styles.buttonWrapper}>
                <Button
                    className={styles.sortButton}
                    onClick={handleButtonClick}
                    title={
                        sortingMode !== SortingMode.None
                            ? "Change sort direction"
                            : "Sort items"
                    }
                >
                    {sortingMode !== SortingMode.None ? (
                        <span className="d-flex no-wrap">
                            <span
                                className={styles.indexNumber}
                                title={tooltipText}
                            >
                                {index + 1}
                            </span>
                            <FontAwesomeIcon
                                icon={sortingIcon}
                                style={iconStyle}
                                size="1x"
                            />
                        </span>
                    ) : (
                        <FontAwesomeIcon
                            icon={faSort}
                            className={styles.faSortIcon}
                        />
                    )}
                </Button>
                {sortingMode !== SortingMode.None && (
                    <span
                        className={`${styles.closeButton}`}
                        onClick={handleCloseClick}
                        title="Remove from sorting"
                    >
                        x
                    </span>
                )}
            </div>
            <Modal
                className={useDarkMode ? "modal-dark" : ""}
                show={showSortingModal}
                onHide={() => setShowSortingModal(false)}
            >
                <Modal.Header closeButton>Sorting modes</Modal.Header>
                <Modal.Body className={`px-3 mb-0 ${styles.sortModal}`}>
                    <p>
                        Sorting is limited to three (3) active options. <br />{" "}
                        Please remove one or reset all before sorting by another
                        mode.
                    </p>
                    <p>
                        Priority 1 - sorts Products, Projects and Revisions{" "}
                        <br />
                        Priority 2 - sorts Projects and Revisions
                        <br /> Priority 3 - sorts Revisions
                    </p>
                </Modal.Body>
            </Modal>
            <Modal
                className={useDarkMode ? "modal-dark" : ""}
                show={showUserQueryModal}
                onHide={() => setShowUserQueryModal(false)}
            >
                <Modal.Header closeButton>Update custom data!</Modal.Header>
                <Modal.Body className={`px-3 mb-0 ${styles.sortModal}`}>
                    <p>
                        No custom data query found. <br /> Select a time range
                        from date picker and press <b>Update</b> to fetch
                        analytics data.
                    </p>
                </Modal.Body>
            </Modal>
        </>
    );
};
