import React, { FC, useEffect } from "react";
import { Card, Col, InputGroup, Row } from "react-bootstrap";
import { AnalyticsTimeRangeDropdown } from "../../dropdowns/analytics/AnalyticsTimeRangeDropdown";
import { AnalyticsQueryType } from "../../../models/types";
import {
    getDatesInRange,
    getFormattedTimeFromUnix,
    getLastAnalyticsUpdateDateString,
    getTimestampRangeForDate
} from "../../../common/Helpers";
import { FunnelDateInput } from "../../simple/analytics/FunnelDateInput";
import { useApolloClient } from "@apollo/client";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import { updateAnalyticsState } from "../../../common/AnalyticsHelpers";
import { SimpleToast } from "../../simple/SimpleToast";
import styles from "./QueryOptions.module.scss";

interface Props {
    apiVersion: string;
    queryType: AnalyticsQueryType;
}

export const QueryOptions: FC<Props> = ({ queryType, apiVersion }) => {
    const client = useApolloClient();
    const analyticsState = useLocalAnalyticsState();
    const { funnelTimestamps } = useLocalAnalyticsState();

    const lastAnalyticsUpdate = getLastAnalyticsUpdateDateString();

    useEffect(() => {
        if (
            analyticsState.includeBuildComparison === true ||
            analyticsState.includeBuildPreview === true
        ) {
            updateAnalyticsState(client, {
                ...analyticsState,
                includeBuildPreview: false,
                includeBuildComparison: false
            });
        }
    }, []);

    const getTimeString = () => {
        if (queryType === AnalyticsQueryType.DaySelectionFunnel) {
            const dateList = getDatesInRange(
                funnelTimestamps.startTimestamp,
                funnelTimestamps.endTimestamp
            );
            return `${getFormattedTimeFromUnix(
                getTimestampRangeForDate(dateList[0]).startTimestamp
            )} - ${getFormattedTimeFromUnix(
                getTimestampRangeForDate(dateList[dateList.length - 1])
                    .endTimestamp
            )}`;
        }
        return `${getFormattedTimeFromUnix(
            funnelTimestamps.startTimestamp
        )} - ${getFormattedTimeFromUnix(funnelTimestamps.endTimestamp)}`;
    };

    const timeString: string | null =
        funnelTimestamps.startTimestamp !== -1 &&
        funnelTimestamps.endTimestamp !== -1
            ? getTimeString()
            : null;

    return (
        <>
            <Card className="mb-3">
                <Card.Header className="d-flex text-inverse-50 align-items-center">
                    <div>
                        <h5 className="text-inverse-75">Query Options</h5>
                    </div>
                </Card.Header>
                <Card.Body className={styles.cardBodyContainer}>
                    <Row>
                        <Col className={styles.colDateCustom}>
                            <FunnelDateInput />
                        </Col>
                        <Col className={styles.colBtnCustom}>
                            <AnalyticsTimeRangeDropdown />
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <b className="ms-2 bread-text text-inverse-75">
                            Add to results
                        </b>
                    </Row>
                    <Row>
                        <div>
                            <InputGroup className="custom-input-background">
                                <InputGroup.Checkbox
                                    title="Include Playable Build Performance Comparison"
                                    className="p-0"
                                    checked={
                                        analyticsState.includeBuildComparison
                                    }
                                    onChange={(event: any) => {
                                        const value = event.target.checked;
                                        updateAnalyticsState(client, {
                                            ...analyticsState,
                                            includeBuildComparison: value
                                        });
                                    }}
                                />
                                <InputGroup.Text className="p-0 pe-3 text-inverse">
                                    Build Performance Comparison
                                </InputGroup.Text>
                            </InputGroup>
                            <InputGroup className="custom-input-background">
                                <InputGroup.Checkbox
                                    title="Include Playable Preview window"
                                    className="p-0"
                                    checked={analyticsState.includeBuildPreview}
                                    onChange={(event: any) => {
                                        const value = event.target.checked;
                                        updateAnalyticsState(client, {
                                            ...analyticsState,
                                            includeBuildPreview: value
                                        });
                                    }}
                                />
                                <InputGroup.Text className="p-0 pe-3 text-inverse">
                                    Playable Preview
                                </InputGroup.Text>
                            </InputGroup>
                            <InputGroup className="custom-input-background">
                                <InputGroup.Checkbox
                                    className="p-0"
                                    title="Include Day Funnels"
                                    aria-label="Include Day Funnels"
                                    checked={analyticsState.includeDayFunnel}
                                    onChange={(event: any) => {
                                        const value = event.target.checked;
                                        updateAnalyticsState(client, {
                                            ...analyticsState,
                                            includeDayFunnel: value
                                        });
                                    }}
                                />
                                <InputGroup.Text className="p-0 pe-3 text-inverse">
                                    Day Funnels
                                </InputGroup.Text>
                            </InputGroup>
                        </div>
                    </Row>
                    <Row className="mt-3 ms-1">
                        {timeString ? (
                            <p className="bread-text text-inverse mb-0">
                                <b className="text-inverse-75">
                                    Selected Time Range
                                </b>
                                <span className="ms-2">{timeString}</span>
                            </p>
                        ) : (
                            <p className="bread-text text-inverse mb-0">
                                <b>No time range selected!</b>
                            </p>
                        )}
                    </Row>
                </Card.Body>
                <Card.Footer className="pb-3">
                    <span className="text-inverse-50 bread-text ms-2">
                        Last Analytics Data Update: {lastAnalyticsUpdate}
                    </span>
                </Card.Footer>
            </Card>
            <SimpleToast />
        </>
    );
};
