import React, { useEffect, useMemo, useState } from "react";
import {
    Badge,
    Nav,
    Form,
    Navbar,
    Tooltip,
    OverlayTrigger,
    Button
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useLocalState } from "../../graphql/hooks";
import {
    MeData,
    ProjectEnvironment,
    StateData,
    AnalyticsStateData
} from "../../models/types";
import {
    GET_LOCAL_ANALYTICS_STATE,
    GET_LOCAL_SORTING_STATE,
    GET_LOCAL_STATE,
    GET_ME
} from "../../graphql/queries";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { UPDATE_ME } from "../../graphql/mutations";
import { NotificationsIcon } from "../simple/NotificationsIcon";
import { UserDropdown } from "../dropdowns/UserDropdown";
import { getEnvironment, replaceSortingState } from "../../common/Helpers";
import { useAuth0 } from "@auth0/auth0-react";
import { ProtectedComponent } from "../../auth/ProtectedComponent";
import { AdminPanelNavLink } from "../links/AdminPanelNavLink";
import seepiaPlatformLight from "../../assets/seepia_platform.png";
import seepiaPlatformDark from "../../assets/seepia_platform_dark.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";
import { useLocalAnalyticsState } from "../../graphql/hooks";
import styles from "./NavigationPanel.module.scss";

export const NavigationPanel = () => {
    const { logout } = useAuth0();
    const location = useLocation();
    const client = useApolloClient();
    const state = useLocalState();
    const analyticsState = useLocalAnalyticsState();
    const [expanded, updateExpanded] = useState(false);
    const { data: { me } = {} } = useQuery<MeData>(GET_ME, {
        fetchPolicy: "cache-only"
    });
    const [updateMe] = useMutation(UPDATE_ME);
    const { data: sortingData } = useQuery(GET_LOCAL_SORTING_STATE);
    const updateAnalyticsState = (analyticsEnabled: boolean) => {
        client.writeQuery<AnalyticsStateData, AnalyticsStateData>({
            query: GET_LOCAL_ANALYTICS_STATE,
            data: {
                analyticsState: {
                    ...analyticsState,
                    analyticsEnabled: analyticsEnabled
                }
            }
        });
    };

    useEffect(() => {
        if (me && me.analyticsMode !== analyticsState.analyticsEnabled) {
            updateAnalyticsState(me.analyticsMode);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [me]);

    const updateDeveloperState = (isDeveloperMode: boolean) => {
        client.writeQuery<StateData, StateData>({
            query: GET_LOCAL_STATE,
            data: { state: { ...state, developerMode: isDeveloperMode } }
        });
        if (!isDeveloperMode && me?.testerMode) {
            updateTesterState(false);
        }
    };

    const updateTesterState = async (testerMode: boolean) => {
        try {
            await updateMe({
                variables: { testerMode: testerMode }
            });
        } catch (error) {
            console.log("[DEBUG] updateTesterState error ", error);
        }
    };

    const toggleAnalyticsMode = async (analyticsMode: boolean) => {
        try {
            await updateMe({
                variables: { analyticsMode: analyticsMode }
            });
        } catch (error) {
            console.log("[DEBUG] analyticsMode error ", error);
        }
    };

    const updateDarkModeState = async (useDarkMode: boolean) => {
        try {
            await updateMe({
                variables: { useDarkMode: useDarkMode }
            });
            client.writeQuery<StateData, StateData>({
                query: GET_LOCAL_STATE,
                data: { state: { ...state, useDarkMode: useDarkMode } }
            });
            document.documentElement.style.setProperty(
                "background-color",
                useDarkMode ? "#36393f" : "#ffffff"
            );
        } catch (error) {
            console.log("[DEBUG] updateDarkModeState error ", error);
        }
    };

    const logoutUser = async () => {
        localStorage.clear();
        logout();
    };

    const environment = getEnvironment();
    const environmentBadge = () => {
        switch (environment) {
            case ProjectEnvironment.Local: {
                return <Badge bg="danger">Local</Badge>;
            }
            case ProjectEnvironment.Development: {
                return <Badge bg="info">Development</Badge>;
            }
            case ProjectEnvironment.Staging: {
                return <Badge bg="warning">Staging</Badge>;
            }
            case ProjectEnvironment.Production: {
                return null;
            }
            default:
                return <Badge bg="danger">Development</Badge>;
        }
    };

    const activeKey = useMemo(() => {
        const matches = location.pathname.match("[^/]+");
        return matches ? matches[0] : null;
    }, [location.pathname]);

    const showAnalyticsSwitch =
        me !== undefined &&
        !["/organization", "/admin"].includes(location.pathname) &&
        !location.pathname.endsWith("customator");

    const clearAnalyticsSorting = () => {
        if (sortingData && sortingData.sortingState) {
            let sortingPriorities = [
                ...sortingData.sortingState.sortingPriorities
            ];
            const analyticsModes = [5, 6, 7, 8, 9, 10, 11, 12];

            const newPriorities = sortingPriorities.filter(
                mode => !analyticsModes.includes(mode)
            );

            if (newPriorities.length >= 1) {
                const lastValue = newPriorities[newPriorities.length - 1];
                while (newPriorities.length < 3) {
                    newPriorities.push(lastValue);
                }
            } else {
                while (newPriorities.length < 3) {
                    newPriorities.push(0);
                }
            }

            replaceSortingState(client, newPriorities);
        }
    };

    return (
        <Navbar
            variant={state.useDarkMode ? "dark" : "light"}
            bg={state.useDarkMode ? "dark" : "light"}
            expand="sm"
            fixed="top"
            onToggle={expanded => updateExpanded(expanded)}
            className={styles.navigationBarBottom}
            collapseOnSelect={true}
        >
            <Navbar.Brand as={Link} to="/home">
                <img
                    src={
                        state.useDarkMode
                            ? seepiaPlatformDark
                            : seepiaPlatformLight
                    }
                    alt="Seepia Platform"
                    height="30"
                />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto" activeKey={activeKey || undefined}>
                    <Nav.Link eventKey="products" as={Link} to="/products">
                        Products
                    </Nav.Link>
                    <Nav.Link
                        eventKey="organization"
                        as={Link}
                        to="/organization"
                    >
                        Organization
                    </Nav.Link>
                    <ProtectedComponent
                        scopes={[
                            "admin",
                            "organizations-admin",
                            "organization-admin"
                        ]}
                    >
                        <AdminPanelNavLink />
                    </ProtectedComponent>
                    <Nav.Link>{environmentBadge()}</Nav.Link>
                </Nav>
                <div className="p-2 ">
                    {showAnalyticsSwitch && (
                        <Form>
                            <Form.Check
                                type="switch"
                                id="analytics-mode-switch"
                                title="Toggle analytics options"
                                label={
                                    <span className="d-sm-none d-md-block text-inverse-50">
                                        Analytics
                                    </span>
                                }
                                className={styles.analyticsSwitch}
                                checked={analyticsState.analyticsEnabled}
                                onChange={(event: any) => {
                                    toggleAnalyticsMode(event.target.checked);
                                    if (!event.target.checked) {
                                        clearAnalyticsSorting();
                                    }
                                }}
                            />
                        </Form>
                    )}
                </div>
                {me !== undefined ? (
                    <>
                        <Nav.Link
                            onClick={() =>
                                updateDarkModeState(!state.useDarkMode)
                            }
                            className="text-inverse-50 p-2 ms-1"
                        >
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        Toggle{" "}
                                        {state.useDarkMode ? "Light" : "Dark"}{" "}
                                        Mode
                                    </Tooltip>
                                }
                            >
                                <div>
                                    <FontAwesomeIcon
                                        icon={
                                            state.useDarkMode ? faSun : faMoon
                                        }
                                        size="lg"
                                    />
                                </div>
                            </OverlayTrigger>
                        </Nav.Link>
                        <Nav.Link className="p-0 pe-1">
                            <NotificationsIcon
                                user={me}
                                mobileMode={expanded}
                            />
                        </Nav.Link>
                        <UserDropdown
                            logoutUser={logoutUser}
                            state={state}
                            updateDeveloperState={updateDeveloperState}
                            updateTesterState={updateTesterState}
                            me={me}
                        />
                    </>
                ) : (
                    <Button onClick={() => logoutUser()}>Logout</Button>
                )}
            </Navbar.Collapse>
        </Navbar>
    );
};
