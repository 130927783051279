import React, { FC, useEffect, useMemo, useState } from "react";
import {
    Badge,
    Button,
    Card,
    Col,
    Collapse,
    Container,
    Form,
    Row
} from "react-bootstrap";
import {
    BatchBuild,
    BatchBuildData,
    BatchBuildState,
    BatchBuildVariables,
    BuildIdVariable,
    BuildType,
    DailyBuildAnalytics,
    FeedbackType,
    NetworkBuild,
    NetworkTest,
    NetworkTestState,
    OrderMode,
    ReadyNotificationData,
    ReleaseType,
    ReportDataV2,
    Revision
} from "../../models/types";
import {
    Link,
    useLocation,
    useNavigate,
    useNavigationType,
    useParams
} from "react-router-dom";
import {
    useApolloClient,
    useLazyQuery,
    useMutation,
    useQuery,
    useSubscription
} from "@apollo/client";
import {
    GET_NETWORK_BUILDS_WITH_FILTER,
    GET_RESULT,
    GET_RESULTS,
    GET_REVISION
} from "../../graphql/queries";
import { NetworkBuildReport } from "../simple/NetworkBuildReport";
import { SearchBar } from "../search/SearchBar";
import { BackButton } from "../simple/BackButton";
import { UPDATE_BUILD } from "../../graphql/mutations";
import { useLocalAnalyticsState, useLocalState } from "../../graphql/hooks";
import {
    faCircleInfo,
    faExclamationCircle,
    faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getFormattedTime, loadBuildPlayState } from "../../common/Helpers";
import { FeedbackModal } from "../modals/FeedbackModal";
import { DailyBadges } from "../simple/analytics/DailyBadges";
import { UserQueryBadges } from "../simple/analytics/UserQueryBadges";
import { LegacyLinkPageButton } from "../buttons/LegacyLinkPageButton";
import { SimpleToast } from "../simple/SimpleToast";
import { BuildLogsModal } from "../modals/BuildLogsModal";
import { ZipDownloadButton } from "../buttons/ZipDownloadButton";
import { Paginator } from "../lists/Paginator";
import { READY_SUBSCRIPTION } from "../../graphql/subscriptions";
import { clearFunnelSelection } from "../../common/AnalyticsHelpers";
import { UpButton } from "../buttons/UpButton";
import { BuildSortButton } from "../buttons/BuildSortButton";
import { AnalyticsFunnelModeToggle } from "../buttons/analytics/AnalyticsFunnelModeToggle";
import cx from "classnames";

interface Props {
    latest: BatchBuild;
    revision: Revision;
}

export const BuildReport: FC<Props> = ({ latest, revision }) => {
    const { jobId } = useParams<{
        jobId: string;
    }>();
    const client = useApolloClient();
    const navigate = useNavigate();
    const location = useLocation();
    const navigationType = useNavigationType();
    const state = useLocalState();
    const { analyticsEnabled } = useLocalAnalyticsState();
    const [funnelSelection, updateFunnelSelection] = useState<NetworkBuild[]>(
        []
    );
    const [accept, updateAccept] = useState(true);
    const [showHelp, setShowHelp] = useState(false);
    const toggleShowHelp = () => setShowHelp(!showHelp);
    const [showFeedbackModal, updateFeedbackModal] = useState(false);
    const [showLogsModal, updateLogsModal] = useState(false);
    const [openModalTo, updateOpenModalTo] = useState<{
        job: undefined | string;
        id: undefined | number;
        buildId: undefined | number;
    }>({
        job: undefined,
        id: undefined,
        buildId: undefined
    });
    const [networkBuilds, updateNetworkBuilds] = useState<NetworkBuild[]>([]);
    const [showBuilds, setShowBuilds] = useState(true);
    const [showFullBuildName, setShowFullBuildName] = useState(false);
    const [showNetworkTestSort, setShowNetworkTestSort] = useState(false);
    const [searchValue, setSearchValue] = useState<string>("");
    const [searchData, setSearchData] = useState<string>("");
    const [isPortrait, setIsPortrait] = useState(
        window.innerWidth < window.innerHeight
    );
    const checkAspectRatio = () => {
        setIsPortrait(window.innerWidth < window.innerHeight);
    };
    const buildHasAnalytics = networkBuilds.some(
        build => build.analyticsEnabled
    );
    const { data: { build } = {} } = useQuery<
        BatchBuildData,
        BatchBuildVariables
    >(GET_RESULT, {
        variables: { id: Number(jobId) },
        fetchPolicy: "network-only"
    });

    const [getNetworkBuilds, { data }] = useLazyQuery<{
        networkBuildsWithFilter: NetworkBuild[];
    }>(GET_NETWORK_BUILDS_WITH_FILTER, { fetchPolicy: "network-only" });
    const [updateBuildStateMutation] = useMutation(UPDATE_BUILD);
    const [sortConfig, setSortConfig] = useState({
        key: "",
        direction: OrderMode.None
    });

    const calculateCtr = (
        analytics: DailyBuildAnalytics | undefined
    ): number => {
        if (!analytics || analytics.impressions === 0) {
            return 0;
        }
        const { gameplay = 0, endScreen = 0, impressions = 0 } = analytics;
        const ctr = ((gameplay + endScreen) / impressions) * 100;
        return parseFloat(ctr.toFixed(1));
    };

    const testStatus = (
        networkTests: NetworkTest[] | undefined
    ): NetworkTest | undefined => {
        if (!networkTests || networkTests.length === 0) {
            return undefined;
        }
        return networkTests.reduce((latest, test) => {
            if (!latest) {
                return test;
            }
            const latestDate = new Date(latest.dateUpdated || 0).getTime();
            const testDate = new Date(test.dateUpdated || 0).getTime();
            return testDate > latestDate ? test : latest;
        }, networkTests[0]);
    };

    useSubscription<ReadyNotificationData, BuildIdVariable>(
        READY_SUBSCRIPTION,
        {
            variables: { id: Number(jobId) },
            onData: ({
                data: { data: { readyNotification } = {} },
                client
            }) => {
                const currentReportData = client.cache.readQuery<
                    BatchBuildData,
                    BatchBuildVariables
                >({
                    query: GET_RESULT,
                    variables: { id: Number(jobId) }
                });

                if (
                    currentReportData &&
                    readyNotification &&
                    readyNotification.success
                ) {
                    const newBuildData = { ...currentReportData.build };
                    const type = readyNotification.type;
                    switch (type) {
                        case "legacyLink":
                            newBuildData.legacyLinkUrl = readyNotification.link;
                            break;
                        case "zip":
                            newBuildData.zipUrl = readyNotification.link;
                            break;
                        default:
                            console.log("[DEBUG] type not implemented ", type);
                            break;
                    }
                    client.cache.writeQuery<
                        BatchBuildData,
                        BatchBuildVariables
                    >({
                        query: GET_RESULT,
                        variables: { id: Number(jobId) },
                        data: {
                            ...currentReportData,
                            build: newBuildData
                        }
                    });
                }
            }
        }
    );

    useEffect(() => {
        clearFunnelSelection(client);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSortClick = (criteria: string) => {
        setSortConfig(prevConfig => {
            if (prevConfig.key === criteria) {
                if (prevConfig.direction === OrderMode.Ascending) {
                    return { key: "", direction: OrderMode.None };
                } else {
                    return { key: criteria, direction: OrderMode.Ascending };
                }
            } else {
                return { key: criteria, direction: OrderMode.Descending };
            }
        });
    };

    const analyticsToTop = (a: NetworkBuild, b: NetworkBuild) => {
        if (a.analyticsEnabled && !b.analyticsEnabled) {
            return -1;
        } else if (!a.analyticsEnabled && b.analyticsEnabled) {
            return 1;
        } else {
            return 0;
        }
    };

    const sortedBuilds = useMemo(() => {
        if (sortConfig.direction === OrderMode.None) {
            return [...networkBuilds];
        }

        return [...networkBuilds].sort((a, b) => {
            let order = 0;
            let valueA, valueB;

            if (
                [
                    "dailyImpressions",
                    "userImpressions",
                    "dailyCTR",
                    "userCTR"
                ].includes(sortConfig.key)
            ) {
                order = analyticsToTop(a, b);
                if (order !== 0) {
                    return order;
                }
            }

            switch (sortConfig.key) {
                case "dailyImpressions":
                    valueA = a.dailyBuildAnalytic?.[0]?.impressions ?? 0;
                    valueB = b.dailyBuildAnalytic?.[0]?.impressions ?? 0;
                    break;
                case "dailyCTR":
                    valueA = calculateCtr(a.dailyBuildAnalytic?.[0]);
                    valueB = calculateCtr(b.dailyBuildAnalytic?.[0]);
                    break;
                case "userImpressions":
                    valueA = a.userQueryBuildAnalytic?.[0]?.impressions ?? 0;
                    valueB = b.userQueryBuildAnalytic?.[0]?.impressions ?? 0;
                    break;
                case "userCTR":
                    valueA = calculateCtr(a.userQueryBuildAnalytic?.[0]);
                    valueB = calculateCtr(b.userQueryBuildAnalytic?.[0]);
                    break;
                case "networkTest":
                    const latestTestA = testStatus(a.networkTest);
                    const latestTestB = testStatus(b.networkTest);
                    const isACompleted =
                        latestTestA?.state === NetworkTestState.Completed;
                    const isBCompleted =
                        latestTestB?.state === NetworkTestState.Completed;

                    if (isACompleted && !isBCompleted) {
                        return sortConfig.direction === OrderMode.Ascending
                            ? -1
                            : 1;
                    } else if (!isACompleted && isBCompleted) {
                        return sortConfig.direction === OrderMode.Ascending
                            ? 1
                            : -1;
                    }
                    return 0;
                default:
                    return 0;
            }

            if (sortConfig.direction === OrderMode.Ascending) {
                return valueA - valueB;
            } else {
                return valueB - valueA;
            }
        });
    }, [networkBuilds, sortConfig]);

    const initialPlayedHistory = useMemo(() => {
        return loadBuildPlayState(Number(jobId));
    }, []);

    useEffect(() => {
        if (data && data.networkBuildsWithFilter) {
            const builds = [...data.networkBuildsWithFilter];
            updateNetworkBuilds(builds);

            const hasNetworkTests = builds.some(
                build => !!build.networkTest?.length
            );
            setShowNetworkTestSort(hasNetworkTests);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        getNetworkBuilds({
            variables: {
                buildId: Number(jobId),
                searchValue: searchValue,
                searchData: searchData,
                sortBy: "id"
            }
        });
    }, [jobId, getNetworkBuilds, searchValue, searchData]);

    useEffect(() => {
        window.addEventListener("resize", checkAspectRatio);
        checkAspectRatio();

        const cleanup = () =>
            window.removeEventListener("resize", checkAspectRatio);

        return cleanup;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (
        !build ||
        build.reportData === undefined ||
        build.reportData === null ||
        !networkBuilds
    ) {
        return (
            <Container className="fullSize p-2 pt-5 text-center text-inverse">
                <h5>Build report data not found</h5>
            </Container>
        );
    }

    const toggleFunnelSelection = (networkBuild: NetworkBuild) => {
        const newFunnelSelection = [...funnelSelection];
        if (!newFunnelSelection.includes(networkBuild)) {
            newFunnelSelection.push(networkBuild);
        } else {
            newFunnelSelection.splice(
                newFunnelSelection.indexOf(networkBuild),
                1
            );
        }
        updateFunnelSelection(newFunnelSelection);
    };

    const isLatest = build.id === latest.id;

    const updateBuildState = async (newState: BatchBuildState) => {
        try {
            await updateBuildStateMutation({
                variables: { id: build.id, state: newState },
                refetchQueries: [
                    {
                        query: GET_RESULTS,
                        variables: { revisionId: revision.id }
                    },
                    {
                        query: GET_REVISION,
                        variables: { revisionId: revision.id }
                    }
                ]
            });
        } catch (error) {
            console.log("[DEBUG] updateBuildState error ", error);
        }
    };

    const openModal = (
        job: string,
        id: number,
        buildId: number | undefined
    ) => {
        updateOpenModalTo({ job: job, id: id, buildId: buildId });
        updateLogsModal(true);
    };

    const reports = sortedBuilds?.map(
        (networkBuild: NetworkBuild, index: number) => (
            <NetworkBuildReport
                key={index}
                buildId={Number(build.id)}
                networkBuild={networkBuild}
                localizations={build.reportData.localizations}
                duplicates={undefined}
                statsKey={`${build.reportData.info.prefix}/${networkBuild.statsFile}`}
                s3bucket={build.reportData.info.bucket}
                s3Key={`${build.reportData.info.prefix}/${networkBuild.filename}`}
                openModal={openModal}
                arrayIndex={index}
                isInFunnel={funnelSelection.includes(networkBuild)}
                toggleFunnelSelection={() =>
                    toggleFunnelSelection(networkBuild)
                }
                buildType={build.buildType}
                showFullBuildName={showFullBuildName}
                buildPlayHistory={initialPlayedHistory}
                cardColor={index % 2 === 0 ? "inner-card" : "inner-card-other"}
            />
        )
    );

    const historyPath = location.pathname.substring(
        0,
        location.pathname.lastIndexOf("/")
    );
    const dateTime = getFormattedTime(build.dateUpdated);
    const editPanelUrl = `/products/${revision.project.product?.id}/projects/${revision.project.id}/revisions/${revision.id}/variations`;

    return (
        <Container
            className={cx("p-2 fullSize", {
                widthBuildReportAnalytics:
                    build.analyticsEnabled && analyticsEnabled,
                widthBuildReport: !build.analyticsEnabled || !analyticsEnabled
            })}
        >
            <Row className="m-0 p-0 pt-2">
                <Col className="mt-1">
                    <BackButton
                        hasHistory={!(location.key && navigationType === "POP")}
                        goBack={() => navigate(-1)}
                        size={"lg"}
                    />
                </Col>
                <Col className="float-end text-end mb-1">
                    {(state.developerMode ||
                        revision.releaseType === ReleaseType.ClientRelease) && (
                        <Link to={editPanelUrl}>
                            <Button className="me-2 mb-1">Build Edit</Button>
                        </Link>
                    )}
                    <Link to={historyPath}>
                        <Button className="mb-1">Build History</Button>
                    </Link>

                    {state.developerMode &&
                    (build.reportData as ReportDataV2)?.version === 2 ? (
                        <Button
                            onClick={() => {
                                updateOpenModalTo({
                                    job: undefined,
                                    id: undefined,
                                    buildId: undefined
                                });
                                updateLogsModal(true);
                            }}
                            className="btn-primary text-end mb-1 ms-2"
                        >
                            Logs
                        </Button>
                    ) : null}
                    {build.analyticsSchema ? (
                        <Button
                            onClick={() =>
                                navigate(`${location.pathname}/analyticsSchema`)
                            }
                            className="btn-primary mb-1 text-end ms-2"
                        >
                            Analytics Schema
                        </Button>
                    ) : null}
                </Col>
            </Row>

            <Card className="text-inverse m-2">
                <Card.Header className="d-flex align-items-center">
                    <h5 className="p-0">
                        {build.revision.project.product?.name} /{" "}
                        {build.revision.project.name} / {build.revision.name}
                    </h5>
                    <div className="ms-1 mb-2">
                        {isLatest ? (
                            <Badge bg="success" className="mx-1">
                                Latest
                            </Badge>
                        ) : latest.state === BatchBuildState.Started ? (
                            <Badge bg="info" className="mx-1">
                                Newer Building
                            </Badge>
                        ) : (
                            <Badge bg="warning" className="mx-1">
                                Outdated
                            </Badge>
                        )}
                        {/*// @ts-ignore*/}
                        {(build.reportData as ReportDataV2)?.pipelineWarnings
                            ?.length > 0 ? (
                            <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                size={"lg"}
                                className={"text-warning ms-2"}
                            />
                        ) : null}
                        {/*// @ts-ignore*/}
                        {(build.reportData as ReportDataV2)?.pipelineErrors
                            ?.length > 0 ? (
                            <FontAwesomeIcon
                                icon={faExclamationCircle}
                                size={"lg"}
                                className={"text-danger ms-2"}
                            />
                        ) : null}
                    </div>
                    <div className="ms-auto float-end p-0">
                        {!state.developerMode &&
                        build.state === BatchBuildState.Review ? (
                            <>
                                <Button
                                    onClick={(event: any) => {
                                        event.preventDefault();
                                        if (
                                            revision.releaseType ===
                                            ReleaseType.Review
                                        ) {
                                            updateAccept(false);
                                            updateFeedbackModal(true);
                                        }
                                    }}
                                    className={cx(
                                        "float-end btn-danger btn-sm ms-2 mb-1",
                                        {
                                            disabled:
                                                revision.releaseType !==
                                                ReleaseType.Review
                                        }
                                    )}
                                >
                                    Reject
                                </Button>
                                <Button
                                    onClick={(event: any) => {
                                        event.preventDefault();
                                        if (
                                            revision.releaseType ===
                                            ReleaseType.Review
                                        ) {
                                            updateAccept(true);
                                            updateFeedbackModal(true);
                                        }
                                    }}
                                    className={cx(
                                        "float-end btn-sm btn-success",
                                        {
                                            disabled:
                                                revision.releaseType !==
                                                ReleaseType.Review
                                        }
                                    )}
                                >
                                    Accept
                                </Button>
                            </>
                        ) : null}
                        {!state.developerMode &&
                        build.state === BatchBuildState.Test ? (
                            <Button
                                onClick={(event: any) => {
                                    event.preventDefault();
                                    updateFeedbackModal(true);
                                }}
                                variant="test"
                                className="float-end text-white btn-sm"
                            >
                                Feedback
                            </Button>
                        ) : null}
                        {state.developerMode &&
                        build.buildType !== BuildType.Mutator &&
                        (build.revision.releaseType ===
                            ReleaseType.Development ||
                            build.revision.releaseType ===
                                ReleaseType.Review) ? (
                            <Button
                                onClick={(event: any) => {
                                    event.preventDefault();
                                    updateBuildState(
                                        build.state === BatchBuildState.Review
                                            ? BatchBuildState.Completed
                                            : BatchBuildState.Review
                                    );
                                }}
                                className="float-end btn-sm text-white ms-2 mb-1"
                                variant="review"
                            >
                                {build.state === BatchBuildState.Review
                                    ? "Untag Review"
                                    : "Tag Review"}
                            </Button>
                        ) : null}
                        {state.developerMode &&
                        build.buildType !== BuildType.Mutator &&
                        build.revision.releaseType ===
                            ReleaseType.Development ? (
                            <Button
                                onClick={(event: any) => {
                                    event.preventDefault();
                                    updateBuildState(
                                        build.state === BatchBuildState.Test
                                            ? BatchBuildState.Completed
                                            : BatchBuildState.Test
                                    );
                                }}
                                variant="test"
                                className="float-end btn-sm ms-1 text-white"
                            >
                                {build.state === BatchBuildState.Test
                                    ? "Untag Test"
                                    : "Tag Test"}
                            </Button>
                        ) : null}
                    </div>
                </Card.Header>
                <Card.Body className="p-2 pt-2 pb-2">
                    <Row>
                        <Col>
                            <div className="p-2 bread-text">
                                <p>
                                    Build ID: {build.id} <br />
                                    Finished: {dateTime} <br />
                                    {build.reportData.localizations
                                        ? "Localizations: " +
                                          build.reportData.localizations?.join(
                                              ", "
                                          )
                                        : null}{" "}
                                    <br />
                                </p>
                            </div>
                        </Col>
                        <Col className="d-flex justify-content-end me-2 align-items-center">
                            {state.developerMode && (
                                <div className="me-2">
                                    <LegacyLinkPageButton
                                        jobId={Number(jobId)}
                                        legacyLink={build.legacyLinkUrl}
                                        zip={build.zipUrl}
                                        pathTemplate={build.pathTemplate}
                                    />
                                </div>
                            )}
                            <ZipDownloadButton
                                buildId={Number(build.id)}
                                zip={build.zipUrl}
                                isLargeBuild={
                                    (networkBuilds &&
                                        networkBuilds.length > 50) ||
                                    false
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col
                            xs={12}
                            lg={isPortrait ? 12 : 2}
                            className="pe-lg-0 pe-xs-4"
                        >
                            <SearchBar
                                info={build.reportData.info}
                                onSearchChange={setSearchValue}
                                onSearchDataChange={setSearchData}
                            />
                        </Col>
                        <Col
                            xs={12}
                            lg={isPortrait ? 12 : 10}
                            className="ps-xs-4"
                        >
                            <Card className="text-inverse">
                                <Card.Header
                                    className="px-3 pt-2 pb-1"
                                    onClick={() => setShowBuilds(!showBuilds)}
                                >
                                    <Row className="g-0">
                                        <Col>
                                            <h5 className="text-inverse-50">
                                                Builds
                                            </h5>
                                        </Col>
                                        {analyticsEnabled ? (
                                            <>
                                                <Col className="pe-2 d-flex align-items-center justify-content-end">
                                                    <DailyBadges
                                                        reference="build"
                                                        referenceId={Number(
                                                            build.id
                                                        )}
                                                    />
                                                </Col>
                                                <Col className="d-flex align-items-center">
                                                    <UserQueryBadges
                                                        reference="build"
                                                        referenceId={Number(
                                                            build.id
                                                        )}
                                                    />
                                                </Col>
                                            </>
                                        ) : null}
                                        <Col className="pe-0 text-end">
                                            {analyticsEnabled &&
                                            buildHasAnalytics ? (
                                                <AnalyticsFunnelModeToggle
                                                    funnelSelection={
                                                        funnelSelection
                                                    }
                                                    reference="networkbuild"
                                                />
                                            ) : null}
                                        </Col>
                                        {!state.developerMode && (
                                            <Col
                                                xs="auto"
                                                className="float-end ms-auto text-inverse-25 ps-2 pt-1"
                                            >
                                                <FontAwesomeIcon
                                                    icon={faCircleInfo}
                                                    onClick={toggleShowHelp}
                                                    className={`mouseHover ms-2 ${
                                                        showHelp
                                                            ? "text-inverse-50"
                                                            : "text-inverse-25"
                                                    }`}
                                                    title={
                                                        showHelp
                                                            ? "Hide Help"
                                                            : "Show Help"
                                                    }
                                                />
                                            </Col>
                                        )}
                                    </Row>
                                </Card.Header>
                                <Card.Body className="p-2">
                                    <Row className="align-items-center no-wrap text-inverse-50 mb-2 me-1">
                                        <Col>
                                            <Form.Check
                                                type="switch"
                                                id="build-name-switch"
                                                title={
                                                    showFullBuildName
                                                        ? "Show only build and variation names"
                                                        : "Show full filenames"
                                                }
                                                label="Full titles"
                                                className="ms-3 bread-text text-inverse-50"
                                                checked={showFullBuildName}
                                                onChange={e =>
                                                    setShowFullBuildName(
                                                        e.target.checked
                                                    )
                                                }
                                            />
                                        </Col>
                                        {analyticsEnabled &&
                                            buildHasAnalytics && (
                                                <>
                                                    <Col xs="auto">
                                                        <BuildSortButton
                                                            criteria="dailyImpressions"
                                                            label="Daily Impr"
                                                            tooltipText="Sort by Impressions - previous day"
                                                            isSelected={
                                                                sortConfig.key ===
                                                                "dailyImpressions"
                                                            }
                                                            sortDirection={
                                                                sortConfig.direction
                                                            }
                                                            onClick={
                                                                handleSortClick
                                                            }
                                                        />
                                                    </Col>
                                                    <Col xs="auto">
                                                        <BuildSortButton
                                                            criteria="dailyCTR"
                                                            label="Daily CTR"
                                                            tooltipText="Sort by CTR - previous day"
                                                            isSelected={
                                                                sortConfig.key ===
                                                                "dailyCTR"
                                                            }
                                                            sortDirection={
                                                                sortConfig.direction
                                                            }
                                                            onClick={
                                                                handleSortClick
                                                            }
                                                        />
                                                    </Col>
                                                    <Col xs="auto">
                                                        <BuildSortButton
                                                            criteria="userImpressions"
                                                            label="User Impr"
                                                            tooltipText="Sort by Impressions - user selected time range"
                                                            isSelected={
                                                                sortConfig.key ===
                                                                "userImpressions"
                                                            }
                                                            sortDirection={
                                                                sortConfig.direction
                                                            }
                                                            onClick={
                                                                handleSortClick
                                                            }
                                                        />
                                                    </Col>
                                                    <Col xs="auto">
                                                        <BuildSortButton
                                                            criteria="userCTR"
                                                            label="User CTR"
                                                            tooltipText="Sort by CTR - user selected time range"
                                                            isSelected={
                                                                sortConfig.key ===
                                                                "userCTR"
                                                            }
                                                            sortDirection={
                                                                sortConfig.direction
                                                            }
                                                            onClick={
                                                                handleSortClick
                                                            }
                                                        />
                                                    </Col>
                                                </>
                                            )}
                                        {showNetworkTestSort && (
                                            <Col xs="auto">
                                                <BuildSortButton
                                                    criteria="networkTest"
                                                    label="Network Test"
                                                    tooltipText="Sort successful network tests to top"
                                                    isSelected={
                                                        sortConfig.key ===
                                                        "networkTest"
                                                    }
                                                    sortDirection={
                                                        sortConfig.direction
                                                    }
                                                    onClick={() =>
                                                        handleSortClick(
                                                            "networkTest"
                                                        )
                                                    }
                                                />
                                            </Col>
                                        )}
                                    </Row>
                                    {!state.developerMode ? (
                                        <Collapse in={showHelp}>
                                            <div>
                                                <Card.Footer className="bread-text no-border text-inverse-50 mb-3 pe-2">
                                                    <>
                                                        <p>
                                                            <b>Builds</b> list
                                                            contains different
                                                            variations of the
                                                            playable ad, which
                                                            were generated in
                                                            accordance to the
                                                            selections in the
                                                            editing stage. This
                                                            list contains tools
                                                            for sharing the ad
                                                            via different types
                                                            of links.
                                                        </p>
                                                        <p>
                                                            Open a playable ad
                                                            variation from the
                                                            list by clicking the
                                                            button with play
                                                            icon and selecting a
                                                            localization or
                                                            default from the
                                                            dropdown. Selecting
                                                            a language
                                                            localization forces
                                                            that over default
                                                            localization. The ad
                                                            will be opened in
                                                            new browser window,
                                                            if it is a html
                                                            build, or downloaded
                                                            as a zip in other
                                                            cases. The link to
                                                            the html build can
                                                            be copied and shared
                                                            from the browser.
                                                            The created link is{" "}
                                                            <b>
                                                                valid for 1 hour
                                                            </b>{" "}
                                                            and stored
                                                            privately. <br />
                                                            The button with a
                                                            QR-code -icon will
                                                            create a temporary
                                                            link to the ad, that
                                                            will be valid for a
                                                            limited time (1 hr).
                                                            This can be used for
                                                            sharing the ad
                                                            quickly and safely.
                                                        </p>
                                                        <p>
                                                            To create a
                                                            permanent public
                                                            link, that can be
                                                            viewed by{" "}
                                                            <b>anyone</b> that
                                                            has access to the
                                                            link, choose the
                                                            button with a curved
                                                            arrow and selecting
                                                            a localization. The
                                                            created link is
                                                            saved on the
                                                            clipboard and can be
                                                            pasted in the
                                                            browser or a text
                                                            file for storage.{" "}
                                                            <br />
                                                            Consider using safer
                                                            methods like a
                                                            private link or a
                                                            zip file for more
                                                            secure sharing of
                                                            files.
                                                            <br />
                                                            All versions in this
                                                            list can be
                                                            downloaded as a zip
                                                            file from a button
                                                            right above the
                                                            Builds list info
                                                            icon.
                                                        </p>
                                                    </>
                                                </Card.Footer>
                                            </div>
                                        </Collapse>
                                    ) : null}
                                    <Paginator
                                        list={reports}
                                        maxElementCount={50}
                                        activeItem={undefined}
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Row className="p-0 m-0 mt-3 justify-content-center mb-2">
                {networkBuilds.length > 20 && <UpButton />}
            </Row>
            <SimpleToast />
            {showFeedbackModal ? (
                <FeedbackModal
                    buildId={build.id}
                    title={
                        build.state === BatchBuildState.Test
                            ? "Test Feedback"
                            : accept
                              ? "Accept Release"
                              : "Reject Release"
                    }
                    type={
                        build.state === BatchBuildState.Test
                            ? FeedbackType.TestFeedback
                            : accept
                              ? FeedbackType.Accept
                              : FeedbackType.Reject
                    }
                    show={showFeedbackModal}
                    revision={revision}
                    onClose={() => updateFeedbackModal(false)}
                />
            ) : null}
            {showLogsModal && state.developerMode ? (
                <BuildLogsModal
                    batchBuildId={Number(build.id)}
                    show={showLogsModal}
                    onClose={() => updateLogsModal(false)}
                    job={openModalTo.job}
                    id={openModalTo.id}
                    targetIndex={openModalTo.buildId}
                    buildReport={build.reportData}
                />
            ) : null}
        </Container>
    );
};
