import React, { useState } from "react";
import { Card, Col, Collapse, Row } from "react-bootstrap";
import { QueryControllerViewAdvanced } from "./QueryControllerViewAdvanced";
import { AnalyticsQueryType } from "../../../models/types";
import { useLocalAnalyticsState, useLocalState } from "../../../graphql/hooks";
import { OverlayBadge } from "../../simple/OverlayBadge";
import { TimeLineChart } from "../../charts/TimeLineChart";
import { MultiTimeLineChart } from "../../charts/MultiTimeLineChart";
import { TimeLineChartSegmented } from "../../charts/TimeLineChartSegmented";
import { MultiTimeLineChartSegmented } from "../../charts/MultiTimeLineChartSegmented";
import { BothHeatmaps } from "../../charts/BothHeatmaps";
import { DayFunnelChartsView } from "./DayFunnelChartsView";
import { NetworkBuildComparisonWrapper } from "../../cards/analytics/NetworkBuildComparisonWrapper";
import { AnalyticsBuildPreview } from "./AnalyticsBuildPreview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const SINGLE_FUNNEL_QUERY_COUNT = 1;

export const FunnelAnalyticsViewImplementation = () => {
    const { useDarkMode } = useLocalState();
    const {
        funnelAnalyticsResults,
        includeBuildComparison,
        includeBuildPreview
    } = useLocalAnalyticsState();

    const comparison =
        funnelAnalyticsResults.length > SINGLE_FUNNEL_QUERY_COUNT;
    const [showComparisons, setShowComparisons] = useState(true);

    return (
        <>
            <QueryControllerViewAdvanced
                queryType={AnalyticsQueryType.Funnel}
            />
            {includeBuildComparison && funnelAnalyticsResults.length > 0 ? (
                <Row>
                    {funnelAnalyticsResults.map((dataset, index) => {
                        return (
                            <Col key={index} xs={12} xl>
                                <Card className="text-inverse mt-2">
                                    <Card.Header
                                        onClick={e => {
                                            e.stopPropagation();
                                            setShowComparisons(
                                                !showComparisons
                                            );
                                        }}
                                        className="d-flex text-inverse justify-content-between align-items-center"
                                    >
                                        <div className="d-flex align-items-center">
                                            <h5>
                                                <span className="text-inverse-75">
                                                    Playable Performance
                                                    Comparison{" "}
                                                </span>
                                                {comparison
                                                    ? dataset.datasetName
                                                    : null}
                                            </h5>
                                            <FontAwesomeIcon
                                                className="ms-2 mb-1 mouseHover"
                                                icon={
                                                    showComparisons
                                                        ? faChevronUp
                                                        : faChevronDown
                                                }
                                                size="sm"
                                            />
                                        </div>
                                        <OverlayBadge
                                            badgeHeader="?"
                                            badgeVariant="secondary"
                                            className="float-end ms-2"
                                            overlayText="Performance comparison of network builds with valid analytics data and 1000+ adStarts"
                                        ></OverlayBadge>
                                    </Card.Header>
                                    <Collapse in={showComparisons}>
                                        <div>
                                            <Card.Body className="pt-1">
                                                <NetworkBuildComparisonWrapper
                                                    data={[dataset]}
                                                />
                                            </Card.Body>
                                        </div>
                                    </Collapse>
                                </Card>
                            </Col>
                        );
                    })}
                </Row>
            ) : null}
            {funnelAnalyticsResults.length > 0 ? (
                <Card className="text-inverse mt-4">
                    <Card.Header className="d-flex text-inverse-75 justify-content-between align-items-center">
                        <h5>Time Medians</h5>
                        <OverlayBadge
                            badgeHeader="?"
                            badgeVariant="secondary"
                            className="float-end ms-2"
                            overlayText="Time medians exclude duplicates (etc.) so they might not match the funnel results exactly"
                        ></OverlayBadge>
                    </Card.Header>
                    <Card.Body>
                        {!comparison ? (
                            <TimeLineChart data={funnelAnalyticsResults} />
                        ) : (
                            <MultiTimeLineChart data={funnelAnalyticsResults} />
                        )}
                    </Card.Body>
                </Card>
            ) : null}
            {funnelAnalyticsResults.length > 0 ? (
                !comparison ? (
                    <TimeLineChartSegmented
                        data={funnelAnalyticsResults}
                        darkMode={useDarkMode}
                    />
                ) : (
                    <MultiTimeLineChartSegmented
                        data={funnelAnalyticsResults}
                    />
                )
            ) : null}
            <Row>
                {includeBuildPreview && funnelAnalyticsResults.length > 0 ? (
                    <Col>
                        <AnalyticsBuildPreview data={funnelAnalyticsResults} />
                    </Col>
                ) : null}
                {funnelAnalyticsResults.length > 0 ? (
                    <BothHeatmaps data={funnelAnalyticsResults} />
                ) : null}
            </Row>
            {funnelAnalyticsResults.length > 0 ? (
                <DayFunnelChartsView data={funnelAnalyticsResults} />
            ) : null}
        </>
    );
};

export const FunnelAnalyticsView = React.memo(
    FunnelAnalyticsViewImplementation
);
