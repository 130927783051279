import React, { FC, ReactElement } from "react";
import {
    faCheckCircle,
    faCircleXmark,
    faCircleStop,
    faQuestionCircle,
    faSpinner,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NetworkTestState, NetworkTest } from "../../../models/types";
import cx from "classnames";

interface Props {
    test: NetworkTest;
}

export const NetworkTestProgressBar: FC<Props> = ({ test }) => {
    const currentState = test.state + 1;
    const getColor = (status: NetworkTestState) => {
        if (test.state === NetworkTestState.Failed) {
            return "danger";
        }
        if (test.state === NetworkTestState.Stopped) {
            return "danger";
        }
        if (status < currentState) {
            return "success";
        }
        if (status === currentState) {
            return "info";
        }
        return "secondary";
    };

    const getIconByStatus = (status: NetworkTestState): IconDefinition => {
        if (test.state === NetworkTestState.Failed) {
            return faCircleXmark;
        }
        if (test.state === NetworkTestState.Stopped) {
            return faCircleStop;
        }
        if (status < currentState) {
            return faCheckCircle;
        }
        if (status === currentState) {
            return faSpinner;
        }
        return faQuestionCircle;
    };

    const stateItems: ReactElement[] = [];
    for (let i = 1; i <= NetworkTestState.Completed; i++) {
        if (i > 1) {
            stateItems.push(<div key={`line-${i}`} className="hr-line"></div>);
        }
        const icon = getIconByStatus(i);
        const color = getColor(i);
        stateItems.push(
            <FontAwesomeIcon
                key={`icon-${i}`}
                icon={icon}
                className={cx(`text-${color}`, {
                    "fa-spin": i === currentState
                })}
                title={NetworkTestState[i]}
            />
        );
    }

    return (
        <div className="ms-2 d-inline-flex align-middle align-items-center">
            {stateItems}
        </div>
    );
};
