import { ProjectEnvironment } from "../models/types";
import { getEnvironment } from "./Helpers";

const arrayBufferToBase64 = (arrayBuffer: ArrayBuffer) => {
    const uint8Array = new Uint8Array(arrayBuffer);
    let binaryString = "";
    for (let i = 0; i < uint8Array.length; i++) {
        binaryString += String.fromCharCode(uint8Array[i]);
    }
    return btoa(binaryString);
};

export const getSceneAsBase64 = async (url: string): Promise<string> => {
    try {
        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();
        const base64String = arrayBufferToBase64(arrayBuffer);
        return base64String;
    } catch (error) {
        console.log("[DEBUG] getSceneAsBase64 with error ", error);
        throw error;
    }
};

export const getAditorUrl = () => {
    const environment = getEnvironment();
    switch (environment) {
        case ProjectEnvironment.Local: {
            return "https://seepia-review.s3.eu-north-1.amazonaws.com/aditor-dev/Aditor.html";
        }
        case ProjectEnvironment.Development: {
            return "https://seepia-review.s3.eu-north-1.amazonaws.com/aditor-dev/Aditor.html";
        }
        case ProjectEnvironment.Staging: {
            return "https://seepia-review.s3.eu-north-1.amazonaws.com/aditor/Aditor.html";
        }
        case ProjectEnvironment.Production: {
            return "https://seepia-review.s3.eu-north-1.amazonaws.com/aditor/Aditor.html";
        }
        default:
            return "https://seepia-review.s3.eu-north-1.amazonaws.com/aditor/Aditor.html";
    }
};
