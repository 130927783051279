import React, { FC } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocalAnalyticsState } from "../../../graphql/hooks";
import { formatDateRange } from "../../../common/AnalyticsHelpers";
import styles from "./DailyBadges.module.scss";

interface Props {
    impressions?: number;
}

const UserQueryImpressionsImplementation: FC<Props> = ({ impressions }) => {
    const { analyticsEnabled } = useLocalAnalyticsState();
    const savedDateRange = localStorage.getItem("user-query-date-range");
    const parsedDateRange = savedDateRange
        ? JSON.parse(savedDateRange)
        : undefined;

    if (
        !analyticsEnabled ||
        !impressions ||
        !savedDateRange ||
        parsedDateRange.length === 0
    ) {
        return null;
    }

    const formattedDateRange = parsedDateRange
        ? formatDateRange(parsedDateRange[0], parsedDateRange[1])
        : "N/A";

    return (
        <>
            <div className={`${styles.analyticsText} text-inverse`}>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 200, hide: 100 }}
                    overlay={
                        <Tooltip id="user-badge-tooltip-impressions">
                            {`Impressions - ${formattedDateRange}`}
                        </Tooltip>
                    }
                >
                    <span>{impressions.toLocaleString()} </span>
                </OverlayTrigger>
            </div>
        </>
    );
};

export const UserQueryImpressions = React.memo(
    UserQueryImpressionsImplementation
);
