import React, { Component } from "react";
import { DSVRowArray } from "d3";
import {
    AnalyticsQueryType,
    AthenaQueryResult,
    ComparisonData
} from "../../../models/types";
import { loadCsv } from "../../../common/AnalyticsHelpers";
import { NetworkBatchComparisonElement } from "./NetworkBatchComparisonElement";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

interface State {
    csvData: DSVRowArray | undefined;
    showAll: boolean;
}

interface Props {
    dataset: AthenaQueryResult[];
}

export class NetworkBuildComparison extends Component<Props, State> {
    state: State = {
        csvData: undefined,
        showAll: false
    };

    shouldComponentUpdate(_: Readonly<Props>, nextState: Readonly<State>) {
        return (
            this.state.csvData !== nextState.csvData ||
            this.state.showAll !== nextState.showAll
        );
    }

    componentDidMount(): void {
        const { dataset } = this.props;
        const funnelData = dataset.find(
            element =>
                element.type === AnalyticsQueryType.NetworkBuildComparisonFunnel
        );
        if (funnelData?.success && funnelData.message) {
            loadCsv(funnelData.message)
                .then(result => {
                    this.setState({ csvData: result });
                })
                .catch(error => console.log("[DEBUG] error ", error));
        }
    }

    toggleShowAll = () => {
        this.setState(prevState => ({
            showAll: !prevState.showAll
        }));
    };

    render() {
        const { csvData, showAll } = this.state;
        if (!csvData || csvData.length === 0) {
            return (
                <div className="p-3 text-inverse-50 analytics-font">
                    No data found. <br />
                    Try selecting a different time range and/or Query selection.
                </div>
            );
        }

        const filteredData = csvData.filter(row => {
            const adStart = Number(row.adStart) || 0;
            return adStart >= 1000;
        });

        if (filteredData.length === 0) {
            return (
                <div className="p-3 text-inverse-50 analytics-font">
                    No data found with significant amount (1000+) of adStarts.{" "}
                    <br />
                    Try selecting a different time range and/or Query selection.
                </div>
            );
        }

        const batches: any = {};
        for (let i = 0; i < filteredData.length; i++) {
            const element: ComparisonData = {
                batch_id: filteredData[i].batch_id || "",
                build_id: filteredData[i].build_id || "",
                adStart: Number(filteredData[i].adStart) || 0,
                ctaBtnPressed: Number(filteredData[i].ctaBtnPressed) || 0,
                CTR: Number(filteredData[i].CTR) || 0
            };
            if (!batches.hasOwnProperty(element.batch_id)) {
                batches[element.batch_id] = {
                    elements: [element],
                    adStart: element.adStart,
                    ctaBtnPressed: element.ctaBtnPressed,
                    average: element.ctaBtnPressed / element.adStart
                };
            } else {
                batches[element.batch_id].elements.push(element);
                batches[element.batch_id].adStart += element.adStart;
                batches[element.batch_id].ctaBtnPressed +=
                    element.ctaBtnPressed;
                batches[element.batch_id].average =
                    batches[element.batch_id].ctaBtnPressed /
                    batches[element.batch_id].adStart;
            }
        }

        const hasMultipleBatches = Object.keys(batches).length > 1;
        let bestBatch = "";
        let bestBatchAverage = 0;
        let worstBatch = "";
        let worstBatchAverage = 0;
        let bestNetworkBuild: any = undefined;
        let worstNetworkBuild: any = undefined;
        for (const key in batches) {
            if (batches[key].average > bestBatchAverage || bestBatch === "") {
                bestBatch = key;
                bestBatchAverage = batches[key].average;
            } else if (
                batches[key].average < worstBatchAverage ||
                worstBatch === ""
            ) {
                worstBatch = key;
                worstBatchAverage = batches[key].average;
            }
            for (let i = 0; i < batches[key].elements.length; i++) {
                if (!bestNetworkBuild && !worstNetworkBuild) {
                    bestNetworkBuild = batches[key].elements[i];
                    worstNetworkBuild = batches[key].elements[i];
                    continue;
                }
                if (
                    bestNetworkBuild &&
                    batches[key].elements[i].CTR > bestNetworkBuild.CTR
                ) {
                    bestNetworkBuild = batches[key].elements[i];
                } else if (
                    worstNetworkBuild &&
                    batches[key].elements[i].CTR < worstNetworkBuild.CTR
                ) {
                    worstNetworkBuild = batches[key].elements[i];
                }
            }
        }

        const showByDefault = (batchId: string) => {
            if (batchId === bestBatch || batchId === worstBatch) {
                return true;
            }
            if (bestNetworkBuild && worstNetworkBuild) {
                return batches[batchId].elements.some(
                    (element: any) =>
                        element.build_id === bestNetworkBuild?.build_id ||
                        element.build_id === worstNetworkBuild?.build_id
                );
            }
            return false;
        };

        const batchElements = [];
        const hasHiddenElements = Object.keys(batches).some(
            key => !showByDefault(key)
        );

        for (const key in batches) {
            const show = showByDefault(key) || showAll;
            batchElements.push(
                <NetworkBatchComparisonElement
                    key={key}
                    data={batches[key]}
                    batchId={key}
                    bestBatch={key === bestBatch}
                    worstBatch={key === worstBatch}
                    bestNetworkBuild={bestNetworkBuild}
                    worstNetworkBuild={worstNetworkBuild}
                    multipleBatches={hasMultipleBatches}
                    show={show}
                />
            );
        }

        return (
            <div className="mt-2">
                {batchElements}
                {hasHiddenElements && (
                    <div className="text-inverse-75 mouseHover float-end ms-auto p-2">
                        <p onClick={this.toggleShowAll}>
                            {showAll ? "Show less" : "Show more"}
                            <FontAwesomeIcon
                                className="ms-2"
                                icon={showAll ? faMinus : faPlus}
                            />
                        </p>
                    </div>
                )}
            </div>
        );
    }
}
