import React, { FC } from "react";
import {
    faCheckCircle,
    faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "react-bootstrap";
import { TestReportField } from "../../models/types";

interface Props {
    reportField: TestReportField;
}

export const NetworkTestReportField: FC<Props> = ({ reportField }) => {
    const yes = (
        <FontAwesomeIcon
            icon={faCheckCircle}
            size="1x"
            className="text-success float-end ms-2"
        />
    );

    const no = (
        <FontAwesomeIcon
            icon={faTimesCircle}
            size="1x"
            className="text-danger float-end ms-2"
        />
    );

    const textClass = reportField.success
        ? "text-success"
        : reportField.severity === "error"
          ? "text-danger"
          : "text-warning";
    return (
        <div>
            <div className="row my-2">
                <div className="col-10">
                    <span className={textClass}>{reportField.text}</span>
                </div>
                <div className="col-2">
                    <span className={textClass}>
                        {reportField.success ? yes : no}
                    </span>
                </div>
            </div>
            {!reportField.success ? (
                <div className="row m-1">
                    <Alert
                        variant={
                            reportField.severity === "error"
                                ? "danger"
                                : "warning"
                        }
                    >
                        {reportField.reason}
                    </Alert>
                </div>
            ) : null}
        </div>
    );
};
